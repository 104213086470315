import { Button } from 'src/components/ui'
import {
  SecurityAuthenticationMethod,
  SecurityDetails,
} from './opc-ua-security-details-step'
import {
  ConnectionStatus,
  OpcUaConnectionConfiguration,
} from '../opc-ua-connection.types'
import { useState } from 'react'
import { TestConnectionModal } from './components'

type Props = {
  disabled?: boolean
  config: OpcUaConnectionConfiguration
  setConfig: (config: OpcUaConnectionConfiguration) => void
  verifyConnection: () => Promise<void>
  handleFinish: () => void
}

export function OpcUaSecurityDetailsStep({
  disabled,
  config,
  setConfig,
  verifyConnection,
  handleFinish,
}: Props): JSX.Element {
  const [testConnectionOpened, setTestConnectionOpened] = useState(false)
  return (
    <>
      <div className="flex flex-col gap-s">
        <div className="flex justify-between gap-s">
          <SecurityDetails
            config={config}
            setConfig={setConfig}
            disabled={disabled}
          />
          <SecurityAuthenticationMethod
            disabled={disabled}
            config={config}
            setConfig={setConfig}
          />
        </div>
        <div className="flex justify-end">
          {!disabled && (
            <Button
              variant="primary"
              title="Test Connection"
              onClick={() => setTestConnectionOpened(true)}
              disabled={
                disabled ||
                !config.securityType ||
                !config.securityPolicy ||
                (!config.securityCertificate &&
                  config.securityPolicy &&
                  config.securityPolicy !== 'NONE') ||
                (!config.securityAnonymous &&
                  (!config.userName || !config.password))
              }
            />
          )}
          {config.securityConnectionState === ConnectionStatus.VERIFIED && (
            <Button variant="primary" title="Close" onClick={handleFinish} />
          )}
        </div>
      </div>
      <TestConnectionModal
        config={config}
        isOpen={testConnectionOpened}
        setIsOpen={setTestConnectionOpened}
        handleSubmit={async () => {
          await verifyConnection()
        }}
      />
    </>
  )
}
