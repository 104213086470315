import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ClientError } from 'graphql-request'
import { datadogRum } from '@datadog/browser-rum'
import { useViewerQuery } from 'account/api'
import { ErrorModal } from 'src/components/ui'
import * as auth from 'src/services/auth'
import { GqlMeFragment } from 'src/services'
import { AuthContext, AuthState } from '.'
import { SplashScreen } from './components'

function hasConsented(user: GqlMeFragment): boolean {
  return (
    user.dataCollectionConsent ||
    (!!user.email && user.email.endsWith('@intelecy.com'))
  )
}

function initializeDatadog(user: GqlMeFragment): void {
  // NOTE: We have a legitimate need for recording rum information:
  // - error detection and tracing
  // - performance optimization
  // However, we should minimize personal information if the user
  // has not consented.
  // Also, we should not record sessions without consent.
  // To stop collection completely we could use:
  // - datadogRum.setTrackingConsent('granted' | 'not-granted');
  if (hasConsented(user)) {
    datadogRum.setUser({
      id: user.id,
      cid: user.customer?.id,
    })
    datadogRum.startSessionReplayRecording()
  } else {
    datadogRum.setUser({
      id: user.id,
      cid: user.customer?.id,
    })
    // NOTE: this shoould already be stopped, but just to be safe...
    datadogRum.stopSessionReplayRecording()
  }
}

interface Props {
  children: React.ReactNode
}

const initialialState: AuthState = {
  state: 'loading',
}

// This component authenticates the user with Auth0.
// On success it loads the Authorize component
export const AuthProvider = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [state, setState] = React.useState<AuthState>(initialialState)

  React.useEffect(() => {
    const authenticate = async (): Promise<void> => {
      if (/^\/callback/.test(pathname)) {
        try {
          const result = await auth.handleRedirectCallback()
          const redirect = result.appState?.url ?? '/'
          navigate(redirect)
        } catch (error) {
          setState({ state: 'error', error })
        }
      } else {
        const authenticated = await auth.isAuthenticated()
        if (authenticated) {
          setState({ state: 'success' })
        } else {
          await auth.loginWithRedirect()
        }
      }
    }

    authenticate().catch(error => setState({ state: 'error', error }))
  }, [pathname, navigate])

  const logout = React.useCallback(() => {
    auth.logout()
    setState(initialialState)
    sessionStorage.clear()
    localStorage.clear()
  }, [])

  switch (state.state) {
    case 'loading':
      return <SplashScreen text="Authenticating..." />
    case 'error':
      return (
        <ErrorModal message="Something went wrong when logging you in. Please try again, or contact Intelecy if the problem presists." />
      )
    case 'success':
      return <Authorize logout={logout}>{children}</Authorize>
    default: {
      const exhaustiveCheck: never = state
      return exhaustiveCheck
    }
  }
}

interface AuthorizeProps {
  logout: () => void
  children: React.ReactNode
}

// This component is only rendered once we have successful authentication
// The component tries to load the user's details from the API
function Authorize({
  children,
  logout: logoutUpstream,
}: AuthorizeProps): JSX.Element {
  const { data: viewer, status, error } = useViewerQuery()

  const logout = React.useCallback(() => {
    datadogRum.clearUser()
    logoutUpstream()
  }, [logoutUpstream])

  switch (status) {
    case 'loading':
      return <SplashScreen text="Authorizing..." />
    case 'error':
      if (error instanceof ClientError) {
        switch (error.response?.status) {
          // This means that the user's token is no longer valid
          // refreshing the window and re-authenticating should fix this
          case 401: {
            const buttonLabel = 'Try again'
            return (
              <ErrorModal
                title="Session expired"
                message={`Click '${buttonLabel}' to reload.`}
                buttonLabel={buttonLabel}
              />
            )
          }
          // This means that the token is valid, but the API forbids the
          // request to load the user. This means that the user is set up in
          // Auth0, but not in the API.
          // We could potentially display a signup form here.
          case 403:
            return (
              <ErrorModal
                message="Your user does not have access to Intelecy. Please contact Intelecy support."
                buttonLabel="Logout"
                action={logout}
              />
            )
          // Not sure what errors these could be?
          default:
            return (
              <ErrorModal message="Something went wrong when logging you in. Please try again, or contact Intelecy if the problem presists." />
            )
        }
      }
      // This should not happen
      return (
        <ErrorModal message="Something went wrong when logging you in. Please try again, or contact Intelecy if the problem presists." />
      )
    case 'success': {
      if (import.meta.env.PROD) {
        initializeDatadog(viewer)
      }
      return (
        <AuthContext.Provider value={{ logout, viewer }}>
          {children}
        </AuthContext.Provider>
      )
    }
  }
}
