import React, { useEffect } from 'react'
import { isEqual } from 'lodash'
import { FetchStatus } from 'src/types'
import useTimeRange from 'src/contexts/timeRange'
import {
  ChartOptions,
  SeriesId,
  seriesIdFromOptions,
} from 'src/types/chartTypes'
import { TimeSeriesData } from 'src/types'
import { tsdArrayMinMax } from 'src/utility/timeSeries'
import { useChartMetadata } from './useChartMetadata'
import { useTimeSeriesData } from 'trend/api'

export interface ChartData {
  data: TimeSeriesData[]
  min: number
  max: number
  precision: number
  // metadata stuff
  // This is not so nice - what we get depends on chart data type
  tagName?: string
  engUnit?: string
  displayName?: string
  name?: string
  type?: {
    name?: string
  }
  tag?: {
    tagName?: string
    engUnit?: string
  }
}

const precision = (diff: number): number => {
  const digits = 2
  // can't do log of 0
  const n = Math.floor(Math.log10(diff || 1)) + 1 - digits
  return 10 ** n
}

const minMaxPrecision = (
  data: TimeSeriesData[],
): [number | undefined, number | undefined, number] => {
  const [min, max] = tsdArrayMinMax(data)
  if (Number.isNaN(min) || Number.isNaN(max)) {
    return [undefined, undefined, precision(1)]
  }
  const p = precision(max - min)
  const minRes = p * Math.floor(min / p)
  const maxRes = p * Math.ceil(max / p)
  return [minRes, maxRes, p]
}

const makeChartData = (data: TimeSeriesData[], metadata: any): ChartData => {
  const [min, max, precision] = minMaxPrecision(data)
  return {
    data,
    min,
    max,
    precision,
    // yuck
    ...metadata,
  }
}

// function for default data
const makeDefaultData = (ids: SeriesId[]): ChartData[] => {
  return ids.map(({ id }) => ({
    id,
    data: [],
    min: 0,
    max: 0,
    precision: 0,
  }))
}

const useChartData = (chart: ChartOptions): [ChartData[], boolean] => {
  const newIds = chart.data.map(seriesIdFromOptions)
  const [ids, setIds] = React.useState(newIds)
  const [data, setData] = React.useState<ChartData[]>(makeDefaultData(ids))
  const { isLiveUpdate, timeRange } = useTimeRange()
  const chartMetadata = useChartMetadata(ids)

  // Reset data if it's not live update of the time range
  useEffect(() => {
    if (!isLiveUpdate) {
      setData(makeDefaultData(ids))
    }
  }, [ids, isLiveUpdate, timeRange])

  if (chart.data.length !== data.length) {
    const newData = makeDefaultData(chart.data.map(seriesIdFromOptions))
    if (!isEqual(newData, data)) {
      setData(newData)
    }
  }

  if (!isEqual(newIds, ids)) {
    setIds(newIds)
  }

  // get timeseries data
  const timeSeriesData = useTimeSeriesData(ids, timeRange)

  // check for pending results
  const isMetadataPending = chartMetadata.some(md =>
    [FetchStatus.notFetched, FetchStatus.pending].includes(md.status),
  )

  const isTimeSeriesPending = timeSeriesData.some(x => x.isLoading)
  const isPending = isMetadataPending || isTimeSeriesPending

  useEffect(() => {
    if (!isPending) {
      const newData = timeSeriesData.map((timeSeries, i) =>
        makeChartData(timeSeries.data ?? [], chartMetadata[i].data),
      )
      // check if new data is different
      if (!isEqual(newData, data)) {
        setData(newData)
      }
    }
  }, [timeSeriesData, chartMetadata, isPending, data, isLiveUpdate, ids])

  return [data, isPending]
}

export default useChartData
