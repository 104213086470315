import { useState, useEffect, useRef } from 'react'
import Highcharts from 'highcharts/highstock'
import useTimeRange from 'src/contexts/timeRange'
import { useTheme } from 'styled-components'
import HighchartsReact from 'highcharts-react-official'
import { useChartWidth } from './useChartWidth'

interface UseThreshold {
  ref: React.RefObject<HighchartsReact.RefObject>
  threshold?: number
}

type YAxisRange = {
  min: number | null
  max: number | null
}

export function useThreshold({ ref, threshold }: UseThreshold): void {
  const line = useRef<Highcharts.SVGElement>()
  const width = useChartWidth(ref)
  const { timeRange } = useTimeRange()
  const [yAxisRange, setYAxisRange] = useState<YAxisRange>()
  const theme = useTheme()

  useEffect(() => {
    const handleRender = (e: any): void => {
      setYAxisRange({
        min: e.target.yAxis[0].min,
        max: e.target.yAxis[0].max,
      })
    }
    const chart = ref.current?.chart

    if (chart) {
      setYAxisRange({
        min: chart.yAxis[0].min,
        max: chart.yAxis[0].max,
      })
      Highcharts.addEvent(chart, 'redraw', handleRender)
    }

    return () => chart && Highcharts.removeEvent(chart, 'redraw', handleRender)
  }, [ref])

  useEffect(() => {
    if (ref.current) {
      const { plotLeft, plotTop, plotWidth, renderer } = ref.current.chart
      line.current = renderer
        .path([
          ['M', plotLeft, plotTop],
          ['L', plotLeft + plotWidth, plotTop],
        ])
        .attr({
          'stroke-width': 2,
          stroke: theme.colors.danger,
          zIndex: 3,
          display: 'none',
          'stroke-dasharray': '8,5',
        })
        .add()
    }

    return () => {
      if (line.current) {
        line.current.destroy()
        // prevent double destroy
        line.current = undefined
      }
    }
  }, [ref, width, theme.colors.danger])

  useEffect(() => {
    if (ref.current && threshold !== undefined && line.current) {
      const { yAxis, plotTop, plotHeight } = ref.current.chart
      const y = yAxis[0].toPixels(threshold, false)

      if (
        y < plotTop ||
        y > plotTop + plotHeight ||
        yAxisRange?.min === undefined
      ) {
        line.current.css({ display: 'none' })
      } else {
        line.current.css({
          transform: `translate3d(0, ${y - plotTop}px, 0)`,
          display: 'initial',
        })
      }
    }
  }, [ref, width, threshold, timeRange, yAxisRange])
}
