import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useMemo, useState } from 'react'
import { ChartMarker, ChartMarkerType } from 'src/types/chartTypes'
import { Icon, TextInput, SelectInput, NumberInput } from 'src/components/ui'
import { MarkerColorSelector } from './MarkerColorSelector'
import { debounce } from 'lodash'

const markerOptions = [
  { label: 'Line', value: ChartMarkerType.LINE },
  { label: 'Range', value: ChartMarkerType.RANGE },
]

export type MarkerProps = {
  marker: ChartMarker
  updateMarker: (marker: ChartMarker) => void
  removeMarker: () => void
}

export function Marker({
  marker,
  updateMarker,
  removeMarker,
}: MarkerProps): JSX.Element {
  const [localMarker, setLocalMarker] = useState(marker)

  const debouncedMarkerUpdate = useMemo(
    () => debounce((marker: ChartMarker) => updateMarker(marker), 600),
    [updateMarker],
  )

  function changeMarkerLabel(label: string): void {
    setLocalMarker({ ...localMarker, label })
    debouncedMarkerUpdate({ ...localMarker, label })
  }

  function handleMarkerTypeChange(type: ChartMarkerType): void {
    if (marker.type === type) return
    if (
      marker.type === ChartMarkerType.LINE &&
      type === ChartMarkerType.RANGE
    ) {
      setLocalMarker({ ...localMarker, type, from: 0, to: 1 })
      updateMarker({ ...localMarker, type, from: 0, to: 1 })
    }
    if (
      marker.type === ChartMarkerType.RANGE &&
      type === ChartMarkerType.LINE
    ) {
      setLocalMarker({ ...localMarker, type, value: 0 })
      updateMarker({ ...localMarker, type, value: 0 })
    }
  }

  function handleMarkerValueChange(value: number): void {
    if (localMarker.type !== ChartMarkerType.LINE) return
    setLocalMarker({ ...localMarker, value })
    debouncedMarkerUpdate({ ...localMarker, value })
  }

  function handleMarkerRangeChange(from: number, to: number): void {
    if (localMarker.type !== ChartMarkerType.RANGE) return
    setLocalMarker({ ...localMarker, from, to })
    debouncedMarkerUpdate({ ...localMarker, from, to })
  }

  return (
    <div className="flex flex-col gap-xs rounded-2xs bg-background-disabled-light p-xs">
      <div className="relative flex items-center justify-between gap-xs">
        <TextInput
          className="w-full"
          containerStyles="!p-2xs"
          placeholder="Add label"
          value={localMarker.label || ''}
          onChange={e => changeMarkerLabel(e.target.value)}
        />
        <Icon
          icon={light('trash-alt')}
          className="cursor-pointer p-2xs text-icon hover:bg-background-hover"
          size="regular"
          onClick={removeMarker}
        />
      </div>
      <div className="flex items-center gap-2xs">
        <SelectInput
          className="min-w-[70px] !p-2xs"
          textVariant="description"
          bold
          value={markerOptions.find(o => o.value === localMarker.type)}
          options={markerOptions}
          onChange={v => handleMarkerTypeChange(v as ChartMarkerType)}
        />
        {localMarker.type === ChartMarkerType.LINE ? (
          <NumberInput
            containerStyles="!p-2xs"
            inputStyles="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            className="max-w-[80px]"
            value={localMarker.value}
            onChange={val => handleMarkerValueChange(val || 0)}
          />
        ) : (
          <>
            <NumberInput
              containerStyles="!p-2xs"
              className="max-w-[60px]"
              inputStyles="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              value={localMarker.from}
              max={localMarker.to}
              onChange={val =>
                handleMarkerRangeChange(val || 0, localMarker.to)
              }
            />
            <NumberInput
              containerStyles="!p-2xs"
              className="max-w-[60px]"
              inputStyles="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              min={localMarker.from}
              value={localMarker.to}
              onChange={val =>
                handleMarkerRangeChange(localMarker.from, val || 1)
              }
            />
          </>
        )}
      </div>
      <div className="flex items-center gap-2xs">
        <MarkerColorSelector marker={marker} updateMarker={updateMarker} />
      </div>
    </div>
  )
}
