import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ErrorInfo, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import toast from 'react-hot-toast'
import { useMySites } from 'orgs-sites/site/api'
import { ErrorDisplay, ErrorPage } from 'pages/app'
import {
  useFactoryTrendViews,
  useCreateFactoryTrendView,
  useUpdateFactoryTrendView,
} from 'trend/api'
import { useSite } from 'src/contexts/site'
import { triggerChartRedraw, useTitle } from 'src/utility'
import { TimeRangeProvider } from 'src/contexts/timeRange'
import { CursorProvider } from 'src/contexts/cursor'
import {
  ChartOptions,
  ChartSize,
  ChartType,
  TableComponentLayout,
} from 'src/types/chartTypes'
import { ChartsProvider, nextChartId, useCharts } from 'src/contexts/charts'
import { logError } from 'src/utility/logging'
import { useAuth } from 'src/contexts/auth'
import { useNavigationContext } from 'src/contexts/navigation'
import { Spinner, TimePickerNavigation } from 'src/components/ui'
import {
  ForecastsTable,
  AnomalyModelsTable,
  TagsTable,
  AddChartModal,
  SaveTrendViewModal,
  TrendLayout,
  LayoutSizePicker,
  ChartTypePicker,
  ShareDropdown,
  TrendNavigation,
  TrendMenu,
  UnsavedChangesModal,
  Dropzone,
} from './components'
import { TrendLandingPage } from './TrendLandingPage'
import { ModalState, SaveTrendProps } from './trend.types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TrendProvider } from './trend.state'
import { GqlFactoryTrendViewFragment } from 'src/services'

const TrendWithLayout = (): JSX.Element => {
  useTitle('Trend')
  const [modalState, setModalState] = useState(ModalState.None)
  const {
    chartConfig: { selectedSize, isFullWidth },
    charts,
    isViewChanged,
    addChart: addChartToContext,
    setChartOrder,
    setChartConfig,
    resetViewChanges,
    loadClearView,
  } = useCharts()
  const [chartType, setChartType] = useState(ChartType.TimeSeries)
  const tabs: { [key: string]: TableComponentLayout } = {
    Tags: TagsTable,
  }
  const mySitesQuery = useMySites()
  const trendViewsQuery = useFactoryTrendViews()
  const createTrendViewMutation = useCreateFactoryTrendView()
  const updateTrendViewMutation = useUpdateFactoryTrendView()

  const navigate = useNavigate()
  const { trendViewId } = useParams()
  const { viewer } = useAuth()

  const { setTitleComponent } = useNavigationContext()

  const { isWorkshop, rootLink, id: factory } = useSite()

  if (!isWorkshop) {
    tabs.Forecasts = ForecastsTable
    tabs['Anomaly Models'] = AnomalyModelsTable
  }

  useEffect(() => {
    setTitleComponent(
      <TrendNavigation
        views={trendViewsQuery.data ?? []}
        viewId={trendViewId}
        charts={charts}
        isViewChanged={isViewChanged}
      />,
    )
    return () => setTitleComponent(null)
  }, [
    setTitleComponent,
    trendViewId,
    trendViewsQuery.data,
    charts,
    isViewChanged,
  ])

  useEffect(() => {
    triggerChartRedraw()
  }, [charts])

  const addNewChart = (chart: ChartOptions): void => {
    addChartToContext(chart)
  }

  const handleSizeChange = useCallback(
    (selectedSize: ChartSize): void => {
      setChartConfig(config => ({ ...config, selectedSize }))
    },
    [setChartConfig],
  )

  const handleIsFullWidthChange = useCallback(
    (isFullWidth: boolean): void => {
      setChartConfig(config => ({ ...config, isFullWidth }))
    },
    [setChartConfig],
  )

  const isLayoutPickerDisabled = useMemo(
    () => charts.length < 2,
    [charts.length],
  )

  const onChartTypeClick = useCallback((type: ChartType): void => {
    setChartType(type)
    setModalState(ModalState.Add)
  }, [])

  const onChartTypeDefaultClick = useCallback(
    () => onChartTypeClick(ChartType.TimeSeries),
    [onChartTypeClick],
  )

  async function handleSaveView({
    name,
    shared,
    duplicate = false,
    clearAfterSave = false,
  }: SaveTrendProps): Promise<void> {
    if (trendViewId && !duplicate) {
      const response = await updateTrendViewMutation.mutateAsync(
        {
          name,
          shared,
          trendViewId,
          data: JSON.stringify({
            charts,
            config: { isFullWidth, selectedSize },
          }),
        },
        {
          onError: () => {
            setModalState(ModalState.None)
            setTimeout(
              () =>
                toast.error('Failed to save trend view', {
                  position: 'top-right',
                }),
              300,
            )
          },
        },
      )

      if (response.id) {
        if (clearAfterSave) {
          loadClearView()
          return
        }
        navigate(`${rootLink}/trend/${response.id}`)
      }
    } else {
      const response = await createTrendViewMutation.mutateAsync(
        {
          name,
          shared,
          factoryId: factory,
          data: JSON.stringify({
            charts,
            config: { isFullWidth, selectedSize },
          }),
        },
        {
          onError: () => {
            setModalState(ModalState.None)
            setTimeout(
              () =>
                toast.error('Failed to save trend view', {
                  position: 'top-right',
                }),
              300,
            )
          },
        },
      )
      if (response.id) {
        if (clearAfterSave) {
          loadClearView()
          return
        }
        navigate(`${rootLink}/trend/${response.id}`)
      }
    }
    setModalState(ModalState.None)
  }

  async function handleClearView(): Promise<void> {
    if (isViewChanged) {
      setModalState(ModalState.Unsaved)
    } else {
      loadClearView()
    }
  }

  async function handleSaveChanges({
    name,
    shared,
  }: SaveTrendProps): Promise<void> {
    const currentView = trendViewsQuery.data?.find(v => v.id === trendViewId)
    const duplicate: boolean =
      name !== currentView?.name ||
      (currentView && viewer.id !== currentView.user.id)
    handleSaveView({ name, shared, duplicate, clearAfterSave: true })
    setModalState(ModalState.None)
  }

  if (trendViewsQuery.isLoading || mySitesQuery.isLoading) {
    return <Spinner />
  }

  if (trendViewsQuery.isError || mySitesQuery.isError) {
    const errorQuery = trendViewsQuery.isError ? trendViewsQuery : mySitesQuery
    return (
      <ErrorDisplay
        message="Failed to load data"
        error={errorQuery.error}
        action={errorQuery.refetch}
        actionTitle="Try again"
      />
    )
  }

  return (
    <TrendProvider>
      <TimeRangeProvider urlQuery>
        <DndProvider backend={HTML5Backend} context={window}>
          <CursorProvider>
            <div className="flex h-[calc(100vh-50px)] flex-col gap-xs py-s pt-0">
              <div className="flex flex-wrap items-center justify-between gap-s px-s">
                <div className="flex items-center gap-xs">
                  <ChartTypePicker
                    onClick={onChartTypeDefaultClick}
                    chartType={ChartType.TimeSeries}
                    setChartType={onChartTypeClick}
                  />
                  <LayoutSizePicker
                    selectedSize={selectedSize}
                    setSelectedSize={handleSizeChange}
                    isFullWidth={isFullWidth}
                    setIsFullWidth={handleIsFullWidthChange}
                    disabled={isLayoutPickerDisabled}
                  />
                  <div className="relative">
                    <TrendMenu
                      menuItems={[
                        {
                          label: 'Save',
                          icon: light('floppy-disk'),
                          onClick: () => setModalState(ModalState.Save),
                          isVisible: true,
                          isDisabled: charts.length < 1,
                        },
                        {
                          label: 'Share',
                          icon: light('share-from-square'),
                          onClick: () => setModalState(ModalState.Share),
                        },
                        {
                          label: 'Reset changes',
                          icon: light('undo'),
                          onClick: resetViewChanges,
                          isVisible: !!trendViewId,
                          isDisabled: !isViewChanged,
                          hasBorder: true,
                        },
                        {
                          label: 'New',
                          icon: light('plus'),
                          onClick: handleClearView,
                          hasBorder: true,
                        },
                      ]}
                    />
                    <ShareDropdown
                      isOpen={modalState === ModalState.Share}
                      setIsOpen={isOpen => {
                        if (isOpen) {
                          setModalState(ModalState.Share)
                        } else {
                          setModalState(ModalState.None)
                        }
                      }}
                    />
                  </div>
                </div>
                <TimePickerNavigation />
              </div>
              <div className="relative flex flex-1 overflow-hidden">
                {charts.length === 0 ? (
                  <TrendLandingPage onButtonClick={onChartTypeClick} />
                ) : (
                  <>
                    <TrendLayout
                      charts={charts}
                      setChartOrder={setChartOrder}
                      selectedSize={selectedSize}
                      isFullWidth={isFullWidth}
                    />
                    <Dropzone />
                  </>
                )}
                {modalState === ModalState.Add && (
                  <AddChartModal
                    isEdit={false}
                    close={() => setModalState(ModalState.None)}
                    onAddChart={addNewChart}
                    chartType={chartType}
                    id={nextChartId(charts)}
                  />
                )}
                {modalState === ModalState.Save && (
                  <SaveTrendViewModal
                    isModalOpen={modalState === ModalState.Save}
                    onClose={() => setModalState(ModalState.None)}
                    siteName={
                      mySitesQuery.data?.find(s => s.id === factory)?.name ??
                      'this factory'
                    }
                    view={trendViewsQuery.data?.find(v => v.id === trendViewId)}
                    onSaveAs={({ name, shared }) =>
                      handleSaveView({
                        name,
                        shared,
                        duplicate: true,
                      })
                    }
                    onSave={handleSaveView}
                    isViewChanged={isViewChanged}
                    canEdit={
                      !trendViewId ||
                      trendViewsQuery.data?.find(v => v.id === trendViewId)
                        ?.user.id === viewer.id
                    }
                    currentUserId={viewer.id}
                    views={trendViewsQuery.data}
                  />
                )}

                <UnsavedChangesModal
                  isModalOpen={modalState === ModalState.Unsaved}
                  onCancel={() => setModalState(ModalState.None)}
                  onDiscard={() => {
                    setModalState(ModalState.None)
                    loadClearView()
                  }}
                  view={trendViewsQuery.data?.find(v => v.id === trendViewId)}
                  onSave={handleSaveChanges}
                  canEdit={
                    !trendViewId ||
                    trendViewsQuery.data?.find(v => v.id === trendViewId)?.user
                      .id === viewer.id
                  }
                  currentUserId={viewer.id}
                  views={trendViewsQuery.data}
                />
              </div>
            </div>
          </CursorProvider>
        </DndProvider>
      </TimeRangeProvider>
    </TrendProvider>
  )
}

function Fallback({ error, resetErrorBoundary }: FallbackProps): JSX.Element {
  return (
    <ErrorDisplay
      message="Error loading charts"
      error={error}
      action={resetErrorBoundary}
      actionTitle="Return to trend"
    />
  )
}

function onError(error: Error, errorInfo: ErrorInfo): void {
  logError(error, {
    source: 'trend',
    errorInfo,
  })
}

export function TrendViewPage(): JSX.Element {
  const { trendViewId } = useParams()
  if (!trendViewId) throw new Error('Missing `trendView` param')

  const trendViewQuery = useFactoryTrendViews(views =>
    views.find(view => view.id === trendViewId),
  )

  if (trendViewQuery.isLoading) {
    return <Spinner />
  }

  if (trendViewQuery.isError) {
    return (
      <ErrorDisplay
        message="Error loading trend view"
        error={trendViewQuery.error}
        action={trendViewQuery.refetch}
        actionTitle="Try again"
      />
    )
  }

  if (!trendViewQuery.data) {
    return (
      <ErrorPage
        message="No trend view found with the requested ID"
        info={[['viewId', trendViewId]]}
        action={trendViewQuery.refetch}
        actionTitle="Try again"
      />
    )
  }

  return <TrendPage trendView={trendViewQuery.data} />
}

type TrendPageProps = {
  trendView?: GqlFactoryTrendViewFragment
}

export function TrendPage({ trendView }: TrendPageProps): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()
  const { id: currentFactory } = useSite()

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onError={onError}
      resetKeys={[searchParams]}
      onReset={() => setSearchParams([])}
    >
      <ChartsProvider id={currentFactory} trendView={trendView}>
        <TrendWithLayout />
      </ChartsProvider>
    </ErrorBoundary>
  )
}
