import { TableConfig } from '../table.types'
import { Text } from 'src/components/ui'

export const getOpcConnectionsTableConfig = (): TableConfig => {
  return {
    allowPaging: true,
    pageSize: 10,
    allowSorting: true,
    columns: [
      {
        field: 'name',
        headerName: 'Connection Name',
        initialFlex: 300,
        cellRenderer: ({ data }: any) => {
          return <Text bold>{data.name || data.id}</Text>
        },
      },
      {
        field: 'endpoint',
        headerName: 'Endpoint',
        initialFlex: 300,
        cellRenderer: ({ data }: any) => {
          return <Text>{data.endpoint}</Text>
        },
      },
    ],
  }
}
