import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Tooltip, Text, Icon } from 'src/components/ui'
import { OnlyModelProps } from 'src/types'
import { prettyErrorScore } from 'src/utility/numbers'
import { PropertyContainer } from './PropertyContainer'

type ScoreDisplayProps = {
  modelType: string
  score: number
  label: string
  tooltipContent: React.ReactNode
}

function ScoreDisplay({
  modelType,
  score,
  label,
  tooltipContent,
}: ScoreDisplayProps): JSX.Element {
  return (
    <PropertyContainer modelType={modelType}>
      <Text bold>{label}:</Text>
      <Text>
        {prettyErrorScore(score)}{' '}
        <Tooltip render={tooltipContent} direction="right">
          <Icon icon={light('info-circle')} size="regular" />
        </Tooltip>
      </Text>
    </PropertyContainer>
  )
}

export function ModelScores({ model }: OnlyModelProps): JSX.Element {
  const { valMae, valRSquare } = model.activeTrainedModel || {}

  if (!valMae && !valRSquare) return <></>

  return (
    <div className="flex flex-col gap-s whitespace-nowrap small:flex-row small:items-center small:gap-l">
      {valMae && (
        <ScoreDisplay
          modelType={model.type.name}
          score={valMae}
          label="Error score"
          tooltipContent={() => (
            <>
              <Text>
                The error score indicates how well a model performs. Its range
                is 0 to 1, and the lower the score, the more likely the model is
                to make an accurate prediction. A value of 0 means that the
                model predicts everything in the validation set perfectly.
              </Text>
              <Text>
                It is calculated by taking the average amount by which the model
                predictions miss the actual value, as a proportion of total
                range of the data. In machine learning terminology, this is
                called the mean absolute error.
              </Text>
              <Text>
                The error score can be used to compare multiple models as long
                as the output tag and time window are identical.
              </Text>
            </>
          )}
        />
      )}
      {valRSquare && (
        <ScoreDisplay
          modelType={model.type.name}
          score={valRSquare}
          label="R-squared"
          tooltipContent={() => (
            <Text>
              R squared or R<sup>2</sup> is a performance metric for
              understanding how well the model can explain the variance between
              the source data, and the predicted values. The R<sup>2</sup> score
              is usually between zero and one, with one indicating a perfect
              prediction, and zero indicating that the model is only able to
              predict the average of the source data. A negative score indicates
              that the model is unable to predict the average value. This can be
              due to extreme source outliers that are skewing the score, or that
              it is simply not possible to predict the output based on the
              selected inputs.
            </Text>
          )}
        />
      )}
    </div>
  )
}
