import { useRef, useEffect, RefObject, useMemo } from 'react'
import { throttle } from 'lodash'
import { useCursor as useCursorHook } from 'src/contexts/cursor'
import useTimeRange from 'src/contexts/timeRange'
import HighchartsReact from 'highcharts-react-official'
import { useChartHeight } from 'src/components/ui'
import { isChartXMouseEvent, isPlotPoint, mapColor, PlotPoint } from 'src/types'

// a map of series id to latest cursor point for that series
type PointMap = { [id: string]: PlotPoint }

const findPoints = (s: Highcharts.Series[], x: number): PointMap => {
  // get all of the series with points
  const series = s.filter(s => s.points && s.points.length > 0)

  const maxTime = Math.max(
    ...series.map(({ points }) => points[points.length - 1]?.x),
  )
  return series.reduce(
    (acc: PointMap, { points, name, userOptions: { id } }) => {
      const point = points
        .filter(isPlotPoint)
        .find(
          (point, i, a) =>
            point.plotX <= x &&
            ((i === a.length - 1 && point.x === maxTime) ||
              (i <= a.length - 2 && a[i + 1].plotX > x)),
        )
      if (point) {
        const key = id ?? name
        acc[key] = point
      }
      return acc
    },
    {},
  )
}

// a map of series id to the trackball svg elements
type Trackballs = { [id: string]: Highcharts.SVGElement[] }

interface UseCursorProps {
  ref: RefObject<HighchartsReact.RefObject>
  withTrackballs?: boolean
  hoverIndex: number | undefined
}

const useCursor = ({
  ref,
  withTrackballs = false,
  hoverIndex,
}: UseCursorProps): void => {
  const [cursor, setCursor] = useCursorHook()
  const { timeRange } = useTimeRange()
  const height = useChartHeight(ref)
  const trackballs = useRef<Trackballs>({})
  const crosshair = useRef<Highcharts.SVGElement | null>(null)

  // See: https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
  const updateCursor = useMemo(
    () => throttle(setCursor, 100, { trailing: true }),
    [setCursor],
  )

  useEffect(() => {
    const h = ref.current
    if (h && h.container.current) {
      h.container.current.addEventListener('mousemove', e => {
        if (isChartXMouseEvent(e)) {
          const { plotLeft, plotWidth } = h.chart
          const x = e.chartX - plotLeft
          const res = x / plotWidth
          if (res >= 0 && res <= 1) {
            updateCursor(res)
          }
        } else {
          console.warn('Expected ChartXMouseEvent, got:', e)
        }
      })
    }
  }, [ref, updateCursor])

  useEffect(() => {
    if (ref.current) {
      crosshair.current = ref.current.chart.renderer
        .path()
        .attr({
          'stroke-width': 1,
          stroke: 'black',
          zIndex: 3,
          display: 'none',
        })
        .add()
    }

    return () => {
      if (crosshair.current) {
        crosshair.current.destroy()
      }
    }
  }, [ref])

  useEffect(() => {
    if (ref.current && crosshair.current) {
      const { plotLeft, plotTop, plotHeight } = ref.current.chart
      crosshair.current.attr(
        'd',
        `M ${plotLeft} ${plotTop} L ${plotLeft} ${plotTop + plotHeight}`,
      )
    }
  }, [ref, height])

  const newTrackballs = ({
    chart: { plotLeft, plotTop, plotWidth, plotHeight, renderer },
    userOptions: { color },
  }: Highcharts.Series): Highcharts.SVGElement[] => {
    const group = renderer
      .g()
      .attr({
        stroke: 'white',
        'stroke-width': 1,
        zIndex: 5,
        fill: color,
      })
      .clip(renderer.clipRect(plotLeft, plotTop, plotWidth, plotHeight))
      .add()

    return Array(2)
      .fill(undefined)
      .map(() =>
        renderer
          .circle(plotLeft, plotTop, 5)
          .css({ display: 'none' })
          .add(group),
      )
  }

  const updateTrackballs = (
    {
      plotX,
      plotY,
      plotHigh,
      plotLow,
      series: {
        type,
        visible,
        userOptions: { color },
      },
    }: PlotPoint,
    trackballs: Highcharts.SVGElement[],
  ): void =>
    (type === 'arearange' ? [plotHigh, plotLow] : [plotY]).forEach((y, index) =>
      trackballs[index].css({
        transform: `translate3d(${plotX}px, ${y}px, 0)`,
        display: visible ? 'initial' : 'none',
        fill: mapColor(color),
      }),
    )

  useEffect(() => {
    if (ref.current) {
      const { chart } = ref.current
      if (cursor) {
        const points = Object.values(
          findPoints([...chart.series], cursor * chart.plotWidth),
        )
        if (points.length > 0) {
          chart.tooltip.refresh(points)
        } else {
          chart.tooltip.destroy()
        }
      } else {
        chart.tooltip.destroy()
      }
    }
  }, [ref, cursor, height, hoverIndex])

  useEffect(() => {
    if (trackballs.current) {
      Object.values(trackballs.current).forEach(a =>
        a?.forEach(t => t?.css({ display: 'none' })),
      )
    }
  }, [timeRange])

  useEffect(() => {
    if (ref.current && cursor && withTrackballs) {
      const { chart } = ref.current
      const points = findPoints([...chart.series], cursor * chart.plotWidth)

      // get all known series ids
      const ids = new Set([
        ...Object.keys(points),
        ...Object.keys(trackballs.current),
      ])

      for (const id of ids) {
        const point = points[id]
        if (point) {
          if (!trackballs.current[id]) {
            trackballs.current[id] = newTrackballs(point.series)
          } else {
            updateTrackballs(point, trackballs.current[id])
          }
        } else if (trackballs.current[id]) {
          trackballs.current[id].forEach(t => t?.css({ display: 'none' }))
        }
      }
    }
  }, [ref, cursor, withTrackballs, height, hoverIndex])

  useEffect(() => {
    if (cursor && ref.current && crosshair.current) {
      crosshair.current.css({
        transform: `translate3d(${
          cursor * ref.current.chart.plotWidth
        }px, 0, 0)`,
        display: 'initial',
      })
    }
  }, [ref, cursor, height])
}

export default useCursor
