import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getOutputTagWarnings, getInputTagWarnings } from 'pages/site/models'
import { Button, Icon, Tooltip } from 'src/components/ui'
import { TagDto } from 'src/services'
import { ModelTypes } from 'src/types'
import { zIndex } from 'src/utility/constants/StyleConstants'

interface MessagesProps {
  error?: boolean
  messages: string[]
  isModal?: boolean
}

const Messages = ({ messages, error, isModal }: MessagesProps): JSX.Element => (
  <Tooltip
    zIndex={isModal ? zIndex.modal + 1 : undefined}
    render={() => messages.map((message, i) => <div key={i}>{message}</div>)}
  >
    <div className="flex flex-col justify-center">
      {error ? (
        <Icon
          icon={light('circle-exclamation')}
          className="text-[2em] text-text-danger"
        />
      ) : (
        <Icon
          icon={light('triangle-exclamation')}
          className="text-[2em] text-icon-warning"
        />
      )}
    </div>
  </Tooltip>
)

interface TagActionProps {
  type: 'Add' | 'Set' | 'Display' | 'Update'
  tag: TagDto
  modelType?: ModelTypes | null
  modelOutputTagName?: string | null
  inputTags?: string[]
  tagAction: (tag: string) => void
  isPending?: boolean
  isModal?: boolean
}

export function TagAction({
  type,
  tag,
  modelType,
  modelOutputTagName,
  inputTags,
  tagAction,
  isPending,
  isModal,
}: TagActionProps): JSX.Element {
  let hasError = false
  let hasWarning = false
  let variant = 'secondary'
  let color: 'default' | 'danger' = 'default'
  let actionText = 'Add'

  if (type === 'Add') {
    hasError = ['Unlabeled'].includes(tag.label ?? 'Unlabeled')
  }

  if (type === 'Set' || type === 'Update') {
    hasError = ['', 'Step', 'Bool', 'String', 'Too Many Values'].includes(
      tag.label ?? '',
    )
    actionText = 'Select'
  }

  if (type === 'Update') {
    hasWarning = ['Few Values', 'Noise'].includes(tag.label ?? '')
  }

  const messages =
    type === 'Set' || type === 'Update'
      ? getOutputTagWarnings(tag, modelType)
      : getInputTagWarnings(tag, modelType, modelOutputTagName)

  if ((type === 'Add' || type === 'Set') && messages.length === 0) {
    variant = 'primary'
  }
  if (type === 'Display') {
    color = 'danger'
    actionText = 'Remove'
  }

  if (hasError) {
    return (
      <div className="grid grid-flow-col items-center gap-4">
        <div />
        <Messages messages={messages} error isModal={isModal} />
      </div>
    )
  }

  return (
    <div className="grid grid-flow-col items-center gap-4">
      {type === 'Update' && (
        <Button
          variant={hasWarning ? 'secondary' : 'primary'}
          title={isPending ? '' : 'Select'}
          isPending={isPending}
          onClick={() => tagAction(tag.tagName)}
        />
      )}
      {type !== 'Update' &&
        (inputTags && inputTags.includes(tag.tagName) ? (
          'Added'
        ) : (
          <Button
            title={actionText}
            variant={variant}
            buttonColor={color}
            onClick={() => tagAction(tag.tagName)}
          />
        ))}
      {type === 'Update' && hasWarning && (
        <Messages messages={messages} isModal={isModal} />
      )}
      {type !== 'Update' && messages.length > 0 && (
        <Messages messages={messages} isModal={isModal} />
      )}
    </div>
  )
}
