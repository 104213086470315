import { ModelTypeStep } from './ModelType'
import { OutputStep } from './Output'
import { InputStep } from './Input'
import { NameStep } from './Name'
import { ModelTypes } from 'src/types'

export interface Record {
  id?: string
  name?: string
  description?: string
  modelType?: ModelTypes | null
  outputTag?: string | null
  inputTags?: string[]
}

export interface Tag {
  label: string
  type?: string
  tagName: string
}

interface RenderData {
  model: Record
  updateModel: (record: Record) => void
}

interface Step {
  label: string
  completed: (model: Record) => boolean
  render: (data: RenderData) => JSX.Element
  maxWidth?: string
}

export const steps: Step[] = [
  {
    label: 'Type',
    completed: model => !!model.modelType,
    render: ({ model: { modelType }, updateModel }) => (
      <ModelTypeStep
        modelType={modelType}
        setModelType={modelType => updateModel({ modelType })}
      />
    ),
    maxWidth: '645px',
  },
  {
    label: 'Output',
    completed: model => !!model.outputTag,
    render: ({ model, updateModel }) => (
      <OutputStep
        modelType={model.modelType}
        outputTag={model.outputTag}
        setOutputTag={outputTag => updateModel({ outputTag })}
        model={model}
      />
    ),
  },
  {
    label: 'Input',
    completed: model => !!model.inputTags && model.inputTags.length > 0,
    render: ({ model, updateModel }) => (
      <InputStep
        outputTag={model.outputTag}
        inputTags={model.inputTags}
        addInputTag={tag =>
          updateModel({
            inputTags: model.inputTags && [...model.inputTags, tag],
          })
        }
        removeInputTag={tag =>
          updateModel({
            inputTags:
              model.inputTags &&
              model.inputTags.filter(inputTag => inputTag !== tag),
          })
        }
        model={model}
      />
    ),
  },
  {
    label: 'Name',
    completed: model => !!model.name,
    render: ({ model: { name, description }, updateModel }) => (
      <NameStep
        name={name}
        description={description}
        setDescription={description => updateModel({ description })}
        setName={name => updateModel({ name })}
      />
    ),
    maxWidth: '600px',
  },
]
