import { DynamicTable, Modal, Text, Button, Spinner } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { TagAction } from 'pages/site/models'
import { useTags } from 'tags/api'
import { getTagOutputListConfig } from 'models/model/table-configs'
import { WithTagProps } from './DisplayInputTags'

interface TagActionProps extends WithTagProps {
  addInputTag: (tag: string) => void
}

interface AddInputTagsModalType extends TagActionProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isError?: boolean
}

type AddInputTagsModalProps = Omit<AddInputTagsModalType, 'tag'>

export function AddInputTagsModal({
  isOpen,
  setIsOpen,
  isError,
  inputTags,
  addInputTag,
  model,
}: AddInputTagsModalProps): JSX.Element {
  const { id: factory } = useSite()
  const { data: tags = [], isLoading } = useTags()

  const actions = {
    renderAction: ({ data: tag }: any) => (
      <TagAction
        type="Add"
        isModal
        tag={tag}
        inputTags={inputTags}
        tagAction={addInputTag}
        modelType={model.modelType}
        modelOutputTagName={model.outputTag}
      />
    ),
  }

  return (
    <Modal
      contentClassName="w-[1200px] !max-w-[90vw]"
      isOpen={isOpen}
      close={() => setIsOpen(false)}
    >
      <div className="flex max-h-[90vh] flex-col gap-[0.5em]">
        <Text variant="title" bold className="my-s !text-2xl">
          Add input tags
        </Text>
        <div className="mx-auto mb-[0.5em] w-full">
          {isLoading ? (
            <Spinner />
          ) : (
            <DynamicTable
              id={`${factory}-AddInputTags`}
              data={
                tags.map(t => ({
                  ...t,
                  displayName: t.displayName || t.tagName,
                })) ?? []
              }
              config={getTagOutputListConfig(actions)}
              selectedRows={inputTags}
              modal={true}
              fuzzySearch
              fuzzySearchField={['displayName', 'parentName', 'label']}
              className="!h-[400px]"
              rowHeight={60}
            />
          )}
        </div>
        <div className="flex items-center justify-between">
          <div>
            {isError && (
              <Text className="text-text-danger">Failed to add input tag</Text>
            )}
          </div>
          <Button
            variant="secondary"
            title="Done"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    </Modal>
  )
}
