import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ErrorDisplay } from 'pages/app'
import { useWorkspace } from 'pages/site/DataExplorerNew/api'
import { getAnalysisIcon } from 'pages/site/DataExplorerNew/data-explorer.utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  BreadcrumbNavigation,
  Button,
  Icon,
  Spinner,
  Text,
  TextAreaInput,
  TextInput,
} from 'src/components/ui'
import { useNavigationContext } from 'src/contexts/navigation'
import { useSite } from 'src/contexts/site'
import { Analysis } from 'src/dex/analysis'
import { WorkspaceState } from 'src/dex/workspace'

export function AnalysisDetailsPage(): JSX.Element {
  const { workspaceId, analysisId } = useParams()
  if (!workspaceId || !analysisId) {
    throw new Error('Analysis id is required')
  }
  const workspaceQuery = useWorkspace(workspaceId)

  if (workspaceQuery.isLoading) {
    return <Spinner />
  }

  if (workspaceQuery.isError) {
    return (
      <ErrorDisplay
        error={workspaceQuery.error}
        message="Failed to fetch the Workspace"
        action={workspaceQuery.refetch}
      />
    )
  }

  const analysis = workspaceQuery.data.analyses[analysisId]
  if (!analysis) {
    return (
      <ErrorDisplay
        error={new Error('Analysis not found')}
        message="Analysis not found"
        action={workspaceQuery.refetch}
      />
    )
  }

  return (
    <AnalysisDetailsPageWithData
      analysis={analysis}
      workspace={workspaceQuery.data}
    />
  )
}

function AnalysisDetailsPageWithData({
  analysis,
  workspace,
}: {
  analysis: Analysis
  workspace: WorkspaceState
}): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [localAnalysis, setLocalAnalysis] = useState(analysis)

  const { setTitleComponent } = useNavigationContext()
  const { rootLink } = useSite()
  useEffect(() => {
    setTitleComponent(
      <BreadcrumbNavigation
        navigationPath={{
          label: 'Data Explorer',
          url: `${rootLink}/data-explorer-new`,
          child: {
            label: workspace.label,
            url: `${rootLink}/data-explorer-new/${workspace.id}`,
            child: {
              label: analysis.label,
              url: `${rootLink}/data-explorer-new/${workspace.id}/analysis/${analysis.id}`,
            },
          },
        }}
      />,
    )
  }, [analysis, rootLink, setTitleComponent, workspace])

  return (
    <div className="m-s mt-0 flex h-[calc(100%-1em)] flex-col rounded-2xs bg-white p-m">
      <div className="mb-s flex w-full justify-between gap-l">
        <div className="flex items-start justify-start gap-l">
          <Icon
            icon={getAnalysisIcon(analysis.kind?.$case ?? 'trend')}
            className="!h-[30px] !w-[36px] text-icon-secondary"
          />
          <div>
            <Text variant="description" className="mb-2xs">
              Analysis
            </Text>
            {isEditing ? (
              <TextInput
                value={localAnalysis?.label ?? ''}
                containerStyles="!py-2xs"
                inputStyles="!text-content !py-0 min-w-[200px]"
                onChange={e => {
                  if (localAnalysis)
                    setLocalAnalysis({
                      ...localAnalysis,
                      label: e.target.value,
                    })
                }}
              />
            ) : (
              <Text variant="content" bold>
                {analysis.label}
              </Text>
            )}
          </div>
          <div className="max-w-[400px]">
            <Text variant="description" className="mb-2xs">
              Description
            </Text>
            {isEditing ? (
              <TextAreaInput
                inputClassName="max-h-[250px] !text-content"
                className="min-w-[400px] max-w-[500px]"
                value={localAnalysis?.description ?? ''}
                minRows={3}
                placeholder="Descriptive text"
                onChange={e => {
                  if (localAnalysis)
                    setLocalAnalysis({
                      ...localAnalysis,
                      description: e.target.value,
                    })
                }}
              />
            ) : (
              <Text variant="content" bold>
                {analysis.description}
              </Text>
            )}
          </div>
        </div>
        <div>
          {isEditing ? (
            <Button
              variant="primary"
              title="Save"
              onClick={() => setIsEditing(false)}
            />
          ) : (
            <div className="relative">
              <Button
                variant="icon"
                icon={regular('ellipsis')}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              {isMenuOpen && (
                <div
                  className="absolute right-0 top-[calc(100%+8px)] flex cursor-default flex-col gap-xs rounded-2xs bg-background p-xs shadow-button"
                  onClick={e => e.stopPropagation()}
                >
                  <button
                    className="flex min-w-[113px] cursor-pointer items-center gap-xs border-0 bg-background transition duration-200 hover:bg-background-hover disabled:cursor-default disabled:hover:bg-background"
                    onClick={() => {
                      setIsMenuOpen(false)
                      setIsEditing(true)
                    }}
                  >
                    <Icon icon={light('pen-circle')} className="w-icon-small" />
                    <Text variant="description" bold>
                      Edit
                    </Text>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
