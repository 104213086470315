import { useParams } from 'react-router-dom'
import { TagData, TagOverview, ConnectedModels } from './components'
import { TimeRangeProvider } from 'src/contexts/timeRange'
import { FilterProvider } from 'src/contexts/filter'

export function TagDetailsPage(): JSX.Element {
  const { tagId } = useParams()
  if (!tagId) throw new Error('`tagId` route param missing')

  return (
    <TimeRangeProvider>
      <FilterProvider>
        <div className="m-[1em] mt-0 flex flex-col gap-s">
          <TagOverview tagName={tagId} />
          <TagData tagName={tagId} />
          <ConnectedModels tagName={tagId} />
        </div>
      </FilterProvider>
    </TimeRangeProvider>
  )
}
