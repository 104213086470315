import '../assets.styles.css'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AssetTagTable } from './AssetTagTable'
import { Icon, Text } from 'src/components/ui'
import { TagDto } from 'src/services'

interface PopUpTagListProps {
  tags: TagDto[]
  onClickCross?: () => void
}

export function PopUpTagList({
  tags,
  onClickCross,
}: PopUpTagListProps): JSX.Element {
  return (
    <div className="popUpTagList">
      <div className="flex items-center justify-between pl-s pr-l pt-s">
        <Text variant="title">
          {' '}
          ALL TAGS{' '}
          {tags.length === 0 && (
            <span className="ml-[20px] font-500">LOADING ...</span>
          )}{' '}
        </Text>

        <Icon
          icon={light('times')}
          className="cursor-pointer"
          onClick={onClickCross}
          size="regular"
        />
      </div>
      <div className="flex max-h-[calc(100%-50px)] flex-1 flex-col">
        <AssetTagTable assetTags={tags} />
      </div>
    </div>
  )
}
