import { useMemo, useRef, useLayoutEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts, {
  AxisLabelsFormatterContextObject,
} from 'highcharts/highstock'
import { useTheme } from 'styled-components'
import useTimeRange from 'src/contexts/timeRange'
import { format } from 'src/utility/numbers'
import { useHeightListener, useLoading, useZoom } from 'src/components/ui'
import useCursor from './useCursor'
import { isMainYAxisOptions, trendChartConfig } from './trendChartConfig'
import 'src/style/style.css'
import { seriesMinMax } from './seriesMinMax'
import { ChartData } from '../../useChartData'
import { ChartOptions } from 'src/types/chartTypes'

const formatter = (label: AxisLabelsFormatterContextObject): string => {
  // we only expect the formatter to be attached to the main y-axis
  // where we add extra fields
  // Maybe we should even error if that is not the case?
  if (!isMainYAxisOptions(label.axis.options)) return ''

  if (label.isLast || label.isFirst) {
    const { data, chart, theme, hoverIndex } = label.axis.options

    const minMax = seriesMinMax(chart, data)

    const labels = data.map((_, index) => {
      return {
        min: minMax[index].min,
        max: minMax[index].max,
        color:
          hoverIndex === index
            ? '#f09'
            : theme.colors.chart[
                (chart.data[index]?.colorIndex || index + chart.id) %
                  theme.colors.chart.length
              ],
        inactive: chart.data[index]?.inactive,
      }
    })

    return `<div style="
        position: relative; 
        ${
          label.isFirst
            ? 'transform: translate(4px, -50%)'
            : 'transform: translate(4px, 50%)'
        };
        display: grid;
        justify-items: end;
      ">
        ${labels
          .filter(({ inactive }) => !inactive)
          .filter(({ min, max }) => (label.isFirst ? min : max) !== undefined)
          .map(
            ({ color, min, max }) =>
              `<div style="color: ${color}; white-space: nowrap;">${
                format(label.isFirst ? min : max) || ''
              }</div>`,
          )
          .join('')}
      </div>`
  }
  return ''
}

type Props = {
  data: ChartData[]
  chart: ChartOptions
  hoverIndex: number | undefined
  isPending: boolean
}

export function TimeSeriesChart({
  data = [],
  chart,
  hoverIndex,
  isPending,
}: Props): JSX.Element {
  const ref = useRef<HighchartsReact.RefObject>(null)
  const { height, wrapperRef } = useHeightListener()
  useCursor({ ref, withTrackballs: true, hoverIndex })
  useLoading({ ref, loading: isPending, data })
  const { timeRange, setTimeRange } = useTimeRange()
  useZoom({ ref, ...timeRange, isPending })

  const theme = useTheme()

  useLayoutEffect(() => {
    if (!ref.current) return
    const { yAxis, series } = ref.current.chart
    series.forEach((series, index) =>
      series.update(
        // @ts-ignore Highcharts allows partial updates, but their typescript doesn't
        {
          color:
            Math.floor(index / 2) === hoverIndex
              ? '#f09'
              : // @ts-ignore we should probably check that defaultColor exists
                series.options.defaultColor,
          zIndex: Math.floor(index / 2) === hoverIndex ? 2 : 1,
        },
        false,
      ),
    )
    yAxis[0].update({
      // @ts-ignore Highcharts allows extra properties, but their typescript doesn't
      hoverIndex,
    })
  }, [hoverIndex])

  useLayoutEffect(() => {
    if (!ref.current) return
    const { yAxis, series } = ref.current.chart
    const minMax = seriesMinMax(chart, data)
    series.forEach((series, i) => {
      const index = Math.floor(i / 2)

      if (!chart.data[index]) return

      const { colorIndex, inactive } = chart.data[index]

      if (!chart.commonY) {
        yAxis[index + 1].setExtremes(
          minMax[index].min,
          minMax[index].max,
          false,
        )
      }
      const color =
        theme.colors.chart[
          (colorIndex || index + chart.id) % theme.colors.chart.length
        ]

      series.update(
        {
          visible: !inactive,
          color: series.options.color === '#f09' ? '#f09' : color,
          // @ts-ignore Highcharts allows extra properties, but their typescript doesn't
          defaultColor: color,
        },
        false,
      )

      if (i % 2 === 1) {
        // @ts-ignore Highcharts allows partial updates, but their typescript doesn't
        series.update({
          data: !chart.data[index].disableRange
            ? // @ts-ignore we should probably check that areaData exists
              series.options.areaData
            : [],
        })
      }
    })

    if (chart.commonY) {
      // Add 5% padding to the y-axis
      // to make sure marker labels are not cut off
      const diff = minMax[0].max - minMax[0].min
      const padding = diff * 0.05

      yAxis[0].setExtremes(
        minMax[0].min - padding,
        minMax[0].max + padding,
        false,
      )
    }

    yAxis[0].update({
      // @ts-ignore Highcharts allows extra properties, but their typescript doesn't
      chart,
    })
  }, [theme, chart, data])

  const highchart = useMemo(
    () => (
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        options={trendChartConfig({
          data,
          setTimeRange,
          height,
          chart,
          theme,
          formatter,
        })}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chart, data, height],
  )

  return (
    <div
      id="trend-chart-wrapper"
      className="h-full px-xs"
      style={{ transform: 'translateZ(0)' }}
      ref={wrapperRef}
    >
      {highchart}
    </div>
  )
}
