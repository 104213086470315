import { zIndex } from 'src/utility/constants/StyleConstants'
import styled from 'styled-components'

interface ContextMenuProps {
  top?: number
  left?: number
}

export const ContextMenu = styled.div<ContextMenuProps>`
  position: absolute;
  background: white;
  box-shadow: 0px 2px 10px #999999;
  border-radius: 5px;
  top: ${({ top }) => top || 0}px;
  left: ${({ left }) => left || 0}px;
  z-index: ${zIndex.assetContextMenu};
`

export const ContextOption = styled.div`
  text-decoration: none;
  padding: 6px 50px 5px 10px;
  min-width: 160px;
  cursor: default;
  font-size: 1em;
  background-color: white;
  color: black;

  & > i {
    margin-right: 5px;
  }

  &:hover {
    filter: brightness(80%);
    transition: filter 0.2s;
  }
`

export const ContextTitle = styled.div`
  width: 100%;
  padding: 10px 10px 0 10px;
  font-weight: 500;
`
