import { DynamicTable, Text, Spinner } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { TagAction } from 'pages/site/models'
import { getTagOutputListConfig } from 'models/model/table-configs'
import { useTags } from 'tags/api'
import { ModelTypes } from 'src/types'
import { Record } from '../steps'
import { DisplayOutputTag } from './components'

interface OutputStepProps {
  modelType?: ModelTypes | null
  outputTag?: string | null
  setOutputTag: (tag: string | null) => void
  model: Record
}

export function OutputStep({
  modelType,
  outputTag,
  setOutputTag,
  model,
}: OutputStepProps): JSX.Element {
  const { id: factory } = useSite()
  const { data: tags = [], isLoading: isPending } = useTags()

  const actions = {
    renderAction: ({ data: tag }: any) => (
      <TagAction
        type="Set"
        tag={tag}
        tagAction={setOutputTag}
        modelType={model.modelType}
        modelOutputTagName={model.outputTag}
      />
    ),
  }

  return (
    <>
      <Text variant="title" bold className="my-m text-center !text-3xl">
        {modelType === 'ForecastModel'
          ? 'Which tag do you want to predict?'
          : modelType === 'AnomalyModel'
          ? 'Which tag do you want to identify anomalies for?'
          : 'Which tag should be the output?'}
      </Text>
      {modelType === 'AnomalyModel' && (
        <Text className="my-s">
          This is the tag you are trying to detect anomalies on. Anomalies will
          be flagged when something unexpected happens in the value of this tag.
        </Text>
      )}
      {outputTag ? (
        <DisplayOutputTag
          outputTag={outputTag}
          setOutputTag={setOutputTag}
          modelType={modelType}
        />
      ) : (
        <div className="flex flex-col" style={{ marginBottom: '0.5em' }}>
          {isPending ? (
            <Spinner />
          ) : (
            <DynamicTable
              id={`${factory}-OutputStep`}
              data={
                tags.map(t => ({
                  ...t,
                  displayName: t.displayName || t.tagName,
                })) ?? []
              }
              config={getTagOutputListConfig(actions)}
              fuzzySearch
              fuzzySearchField={['displayName', 'parentName', 'label']}
              className="!h-[500px]"
              rowHeight={60}
            />
          )}
        </div>
      )}
    </>
  )
}
