import {
  ChartAxis,
  ChartType,
  SeriesId,
  SeriesOptions,
} from 'src/types/chartTypes'
import { ModelState } from 'src/pages/site/models'
import { Checkbox, Icon, Text, TableConfig } from 'src/components/ui'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getModelSubType, label } from 'models/model/model.utils'
import { minutesToMilliseconds, secondsToMilliseconds } from 'date-fns'

const forecastOffset: {
  [key: string]: number
} = {
  'Forecast 1H': minutesToMilliseconds(60),
  'Forecast 2H': minutesToMilliseconds(120),
  'Forecast 4H': minutesToMilliseconds(240),
}

const dataId = (
  id: string,
  methodName: string,
  forecastHorizon?: number,
): SeriesId => ({
  id,
  type: 'forecast',
  offsetMs: forecastHorizon
    ? secondsToMilliseconds(forecastHorizon)
    : forecastOffset[methodName],
})

interface Actions {
  chartType: ChartType
  addSeries: (data: SeriesOptions, position?: ChartAxis) => void
  removeSeries: (id: SeriesId) => void
}

export const getForecastConfig = ({
  chartType,
  addSeries,
  removeSeries,
}: Actions): TableConfig => {
  let selectionColums: any[] = []
  if (chartType === ChartType.TimeSeries) {
    selectionColums = [
      {
        field: 'isSelected',
        headerName: '',
        width: 50,
        minWidth: 50,
        menuTabs: [],
        cellRenderer: ({
          data: { id, method, isSelected, forecastHorizon },
        }: any) => {
          const series = dataId(id, method.name, forecastHorizon)
          return (
            <Checkbox
              value={isSelected}
              onChange={val => {
                if (val) addSeries(series)
                else removeSeries(series)
              }}
            />
          )
        },
        // colAllowFiltering: false,
        sortable: false,
      },
    ]
  } else {
    selectionColums = [
      {
        headerName: 'x',
        field: 'selectedX',
        headerComponent: () => <Icon icon={light('square-x')} size="big" />,
        width: 60,
        minWidth: 60,
        menuTabs: [],
        cellRenderer: ({
          data: { id, method, selectedX, forecastHorizon },
        }: {
          data: {
            id: string
            method: any
            selectedX?: boolean
            forecastHorizon?: number
          }
        }) => {
          const series = dataId(id, method.name, forecastHorizon)
          return (
            <div
              className="flex aspect-square w-[14px] items-center justify-center rounded-full border border-solid border-black"
              onClick={() => addSeries(series, ChartAxis.X)}
            >
              {selectedX && (
                <div className="aspect-square w-[7px] rounded-full bg-background" />
              )}
            </div>
          )
        },
      },
      {
        headerName: 'Y',
        field: 'selectedY',
        headerComponent: () => <Icon icon={light('square-y')} size="big" />,
        width: 60,
        minWidth: 60,
        menuTabs: [],
        cellRenderer: ({
          data: { id, method, selectedY, forecastHorizon },
        }: {
          data: {
            id: string
            method: any
            selectedY?: boolean
            forecastHorizon?: number
          }
        }) => {
          const series = dataId(id, method.name, forecastHorizon)
          return (
            <div
              className="flex aspect-square w-[14px] items-center justify-center rounded-full border border-solid border-black"
              onClick={() => addSeries(series, ChartAxis.Y)}
            >
              {selectedY && (
                <div className="aspect-square w-[7px] rounded-full bg-background-brand" />
              )}
            </div>
          )
        },
      },
    ]
  }

  return {
    pageSize: 25,
    pageSizes: [5, 10, 25, 100],
    allowPaging: true,
    allowSorting: true,
    disallowSelection: true,
    columns: [
      ...selectionColums,
      {
        field: 'name',
        headerName: 'Name',
        initialFlex: 130,
        cellRenderer: ({ value }: any) => {
          return <Text>{value}</Text>
        },
      },
      {
        field: 'tag.displayTagName',
        headerName: 'Tag Name',
        initialFlex: 130,
        cellRenderer: ({
          data: {
            tag: { displayTagName, tagName },
          },
        }: any) => (
          <div title={tagName}>
            <Text>{displayTagName}</Text>
          </div>
        ),
      },
      {
        field: 'method.name',
        headerName: 'Method',
        initialFlex: 150,
        filterValueGetter: ({ data }: any) =>
          `Forecast ${getModelSubType(data.method.name, data.forecastHorizon)}`,
        cellRenderer: ({
          data: {
            method: { name },
            forecastHorizon,
          },
        }: any) => {
          return <Text>Forecast {getModelSubType(name, forecastHorizon)}</Text>
        },
      },
      {
        field: 'state',
        headerName: 'State',
        initialFlex: 125,
        // Format filter options to human readable labels
        filterValueGetter: ({ getValue }) => {
          return label(getValue('state'))
        },
        cellRenderer: ({ value }: any) => (
          <ModelState state={value} title={value} />
        ),
      },
    ],
  }
}
