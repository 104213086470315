import { useQuery, UseQueryResult } from '@tanstack/react-query'
import * as api from 'src/services'

export const GATEWAY_QUERY = 'gateway'
export const GATEWAYS_QUERY = 'gateways'

export function useGateway(
  factoryId: string,
  agentId: string,
): UseQueryResult<api.GqlGatewayFragment, Error> {
  return useQuery({
    queryKey: [GATEWAY_QUERY, factoryId, agentId],
    queryFn: async () => {
      const data = await api.fetchFactoryGateway(factoryId, agentId)
      if (!data) {
        throw new Error('Gateway not found')
      }
      return data
    },
  })
}

export function useGateways(
  factoryId: string,
): UseQueryResult<api.GqlGatewayFragment[], Error> {
  return useQuery({
    queryKey: [GATEWAYS_QUERY, factoryId],
    queryFn: async () => api.fetchFactoryGateways({ factory: factoryId }),
    enabled: !!factoryId,
  })
}

export function useGatewayReleases<T = api.GatewayRelease[]>(
  select?: (data: api.GatewayRelease[]) => T,
): UseQueryResult<T, Error> {
  return useQuery({
    queryKey: ['gatewayReleases'],
    queryFn: fetchGatewayReleases,
    select,
  })
}

async function fetchGatewayReleases(): Promise<api.GatewayRelease[]> {
  return await api.fetchGatewayReleases()
}

export function useGatewayRelease(
  id: string,
): UseQueryResult<api.GatewayRelease | undefined, Error> {
  return useGatewayReleases(d => d.find(r => r.id === id))
}
