import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import { Text, Spinner, Icon } from 'src/components/ui'
import { FetchModel } from 'src/services'
import { TimeRange } from 'src/types'
import { IconSize } from '../icons/Icon'

type Variant = {
  [key: string]: {
    buttonStyle: string
    buttonColor?: {
      default: string
      danger: string
    }
    textStyle: string
    textColor?: {
      default: string
      danger: string
    }
    iconStyle?: string
    spinnerColor?: string
  }
}

const variants: Variant = {
  primary: {
    buttonStyle:
      'outline-none flex items-center justify-center gap-2xs whitespace-nowrap border-none !px-s py-xs rounded-2xs disabled:bg-background-disabled transition duration-300 cursor-pointer group hover:shadow-button active:shadow-none disabled:hover:shadow-none disabled:cursor-default',
    buttonColor: {
      default:
        'bg-background-brand hover:bg-background-brand-secondary-hover active:bg-background-brand-secondary-active',
      danger: 'bg-background-danger hover:bg-background-danger-hover',
    },
    textStyle: 'text-white',
    iconStyle: 'text-white -ml-2xs',
    spinnerColor: 'white',
  },
  secondary: {
    buttonStyle:
      'flex items-center justify-center gap-2xs outline-none whitespace-nowrap border-[1px] border-solid !px-s py-xs rounded-2xs transition duration-300 cursor-pointer group no-underline text-current active:bg-background-disabled disabled:bg-background-disabled disabled:border-border-disabled hover:shadow-button active:shadow-none disabled:hover:shadow-none disabled:cursor-default',
    buttonColor: {
      default:
        'border-border-brand bg-background hover:bg-background-brand-hover active:bg-background-disabled',
      danger:
        'border-border-danger bg-background hover:bg-background-danger-tertiary-hover',
    },
    textStyle: 'group-disabled:text-text-disabled transition duration-300',
    iconStyle:
      '-ml-xs w-[18px] h-[18px] group-disabled:text-text-disabled transition duration-300',
  },
  tertiary: {
    buttonStyle:
      'outline-none whitespace-nowrap border-none bg-transparent !px-s py-xs rounded-2xs transition duration-300 cursor-pointer group',
    textStyle: 'transition duration-300 ',
    textColor: {
      default:
        'text-text-brand group-hover:text-text-brand-secondary group-active:text-text-brand-onbrand group-disabled:text-text-disabled',
      danger:
        'text-text-danger group-hover:text-text-danger-secondary group-active:text-text-danger-onbrand group-disabled:text-text-disabled',
    },
  },
  icon: {
    buttonStyle:
      'flex cursor-pointer place-content-center rounded-2xs border border-solid border-border-brand bg-background py-xs px-s transition duration-300 hover:bg-background-brand-hover active:bg-background-hover disabled:border-border-disabled disabled:bg-background-disabled disabled:text-text-disabled group hover:shadow-button active:shadow-none disabled:hover:shadow-none disabled:cursor-default',
    textStyle: '',
    iconStyle:
      'group-hover:text-text-brand-secondary group-active:text-text-brand-onbrand group-disabled:text-text-disabled transition duration-300',
  },
  'icon-primary': {
    buttonStyle:
      'flex whitespace-nowrap justify-center items-center outline-none border-[1px] border-solid border-border-brand bg-background !pl-xs !pr-s py-xs rounded-2xs hover:bg-background-brand-hover active:bg-background-hover disabled:bg-background-disabled disabled:border-border-disabled transition duration-300 cursor-pointer group  hover:shadow-button active:shadow-none disabled:hover:shadow-none disabled:cursor-default',
    textStyle: 'group-disabled:text-text-disabled transition duration-300',
    iconStyle:
      'text-text-brand group-hover:text-text-brand-secondary group-active:text-text-brand-onbrand mr-xs group-disabled:text-text-disabled transition duration-300',
  },
  'icon-secondary': {
    buttonStyle:
      'flex whitespace-nowrap justify-center items-center outline-none border-none bg-transparent !px-0 py-xs rounded-2xs transition duration-300 cursor-pointer disabled:cursor-default group',
    textStyle:
      'group-hover:text-text-brand-secondary group-active:text-text-brand-onbrand group-disabled:text-text-disabled transition duration-300',
    iconStyle:
      'mr-xs text-text-brand group-hover:text-text-brand-secondary group-active:text-text-brand-onbrand group-disabled:text-text-disabled transition duration-300',
  },
}
interface BaseProps
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'children'> {
  textVariant?:
    | 'code'
    | 'title'
    | 'small'
    | 'content'
    | 'content-rubik'
    | 'description'
    | undefined
  buttonColor?: 'default' | 'danger'
  textStyle?: string
  icon?: IconProp
  iconSize?: IconSize
  disabled?: boolean
  as?: any
  href?: string
  target?: string
  timeRange?: TimeRange
  model?: FetchModel
  tags?: any
  to?: string
  isPending?: boolean
}

type VariantType = keyof typeof variants

interface ButtonPropsWithIconVariant extends BaseProps {
  variant: 'icon'
  title?: string
}

interface ButtonPropsWithOtherVariants extends BaseProps {
  variant: Exclude<VariantType, 'icon'>
  title: string
}

export type ButtonProps =
  | ButtonPropsWithIconVariant
  | ButtonPropsWithOtherVariants

export default function Button({
  title,
  variant,
  textVariant,
  className,
  buttonColor,
  textStyle,
  icon,
  iconSize,
  disabled,
  isPending,
  as: Tag = 'button',
  ...props
}: ButtonProps): JSX.Element {
  return (
    <Tag
      className={classNames(
        variants[variant].buttonStyle,
        variants[variant].buttonColor?.[buttonColor || 'default'],
        className,
        'no-underline',
      )}
      disabled={disabled}
      {...props}
    >
      {isPending && (
        <Spinner small inline color={variants[variant].spinnerColor} />
      )}
      {icon && (
        <Icon
          icon={icon}
          className={variants[variant].iconStyle && variants[variant].iconStyle}
          size={iconSize ?? 'regular'}
        />
      )}
      {title && (
        <Text
          variant={textVariant ?? 'content'}
          bold
          className={classNames(
            variants[variant].textStyle,
            variants[variant].textColor?.[buttonColor || 'default'],
            textStyle,
          )}
        >
          {title}
        </Text>
      )}
    </Tag>
  )
}
