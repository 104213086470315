import { ResponseError } from 'src/services'
import { ZodError } from 'zod'
import { ErrorPage } from '../../pages/ErrorPage'

interface ErrorDisplayProps {
  error: unknown
  message?: string
  action?: () => void
  actionTitle?: string
}

export function ErrorDisplay({
  error,
  message,
  action,
  actionTitle,
}: ErrorDisplayProps): JSX.Element {
  const info: [string, string][] = [
    ['URL', window.location.href],
    ...errorInfo(error),
  ]
  return (
    <ErrorPage
      message={message || ''}
      info={info}
      action={action}
      actionTitle={actionTitle}
    />
  )
}

function errorInfo(error: unknown): [string, string][] {
  if (error instanceof ResponseError) {
    return responseErrorInfo(error)
  }
  if (error instanceof ZodError) {
    return zodErrorInfo(error)
  }
  if (error instanceof Error) {
    return [
      ['Type', error.name],
      ['Error', error.message],
    ]
  }
  return [['Error', 'Unknown error']]
}

function responseErrorInfo(error: ResponseError): [string, string][] {
  const resp = error.response
  return [
    ['Type', 'Response Error'],
    ['Requested URL', resp.url],
    ['Status code', resp.status.toString()],
    ['Status text', resp.statusText],
    ['Request Id', error.traceparent || ''],
  ]
}

function zodErrorInfo(error: ZodError): [string, string][] {
  const info = error.issues.map<[string, string]>(issue => {
    return [`Path: ${JSON.stringify(issue.path)}`, issue.message]
  })
  return [['Type', 'Validation Error'], ...info]
}
