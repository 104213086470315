import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ErrorDisplay, ErrorPage } from 'pages/app'
import { DynamicTable, Icon, Spinner, Text } from 'src/components/ui'
import { useMySites } from 'orgs-sites/site/api'
import { useSite } from 'src/contexts/site'
import { useAuth } from 'src/contexts/auth'
import {
  useUpdateFactoryTrendView,
  useDeleteFactoryTrendView,
  useFactoryTrendViews,
} from 'trend/api'
import { EditViewModal, DeleteViewModal } from './components'
import { getTrendViewsTableConfig } from './trendViewsTable.config'
import { Site } from 'src/types'
import { GqlFactoryTrendViewFragment } from 'src/services'

export function TrendViewsPage(): JSX.Element {
  const { id: currentFactory } = useSite()
  const mySitesQuery = useMySites()
  const trendViewQuery = useFactoryTrendViews()

  if (mySitesQuery.isLoading || trendViewQuery.isLoading) return <Spinner />

  if (mySitesQuery.isError || trendViewQuery.isError) {
    const errorQuery = mySitesQuery.isError ? mySitesQuery : trendViewQuery
    return (
      <ErrorDisplay
        error={errorQuery}
        message="Failed to load data"
        action={errorQuery.refetch}
      />
    )
  }

  const site = mySitesQuery.data.find(site => site.id === currentFactory)
  if (!site) {
    return (
      <ErrorPage
        message="Site not found"
        action={mySitesQuery.refetch}
        info={[['siteId', currentFactory]]}
      />
    )
  }

  return <Display site={site} trendViews={trendViewQuery.data} />
}

function Display({
  site,
  trendViews,
}: {
  site: Site
  trendViews: GqlFactoryTrendViewFragment[]
}): JSX.Element {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)
  const [viewId, setViewId] = useState<string>('')
  const { viewer } = useAuth()
  const navigate = useNavigate()

  const deleteViewMutation = useDeleteFactoryTrendView()
  const updateViewMutation = useUpdateFactoryTrendView()

  const selectedView = trendViews.find(el => el.id === viewId)

  function handleNavigateToCreatedTrendView(data: any): void {
    navigate(`/site/${site.id}/trend/${data.id}`)
  }

  async function handleEditView({
    name,
    shared,
  }: {
    name: string
    shared: boolean
  }): Promise<void> {
    const currentView = trendViews.find(v => v.id === viewId)
    if (!currentView) return

    await updateViewMutation.mutateAsync(
      {
        data: currentView.data,
        name,
        shared,
        trendViewId: viewId,
      },
      {
        onError: () => {
          setIsEditOpen(false)
          setTimeout(() => {
            toast.error('Failed to update the trend view', {
              position: 'top-right',
            })
          }, 300)
        },
      },
    )
    setIsEditOpen(false)
  }

  async function handleDeleteView(): Promise<void> {
    await deleteViewMutation.mutateAsync(
      { trendViewId: viewId },
      {
        onError: () => {
          setIsDeleteOpen(false)
          setTimeout(() => {
            toast.error('Failed to delete the trend view', {
              position: 'top-right',
            })
          }, 300)
        },
      },
    )
    setIsDeleteOpen(false)
  }

  return (
    <div className="m-[1em] flex h-[calc(100%-2em)] flex-1 flex-col rounded-2xs bg-background px-s">
      <div className="flex items-center gap-l px-xs py-m">
        <Icon
          icon={light('industry-windows')}
          className="!h-[30px] !w-[36px] text-icon-secondary"
        />
        <div>
          <Text variant="description" className="mb-2xs">
            Site
          </Text>
          <Text variant="content" bold>
            {site.name}
          </Text>
        </div>
        <div>
          <Text variant="description" className="mb-2xs">
            Organization
          </Text>
          <Text variant="content" bold>
            {site.orgName}
          </Text>
        </div>
      </div>
      <Text variant="description" className="pb-s">
        Your <span className="font-500">Saved Views</span> available for this
        site.
      </Text>
      <DynamicTable
        id={`${site.id}-factoryTrendViews`}
        data={trendViews}
        headerSummary={`${trendViews.length} Views`}
        config={getTrendViewsTableConfig({
          actions: {
            delete: (id: string) => {
              setViewId(id)
              setIsDeleteOpen(true)
            },
            edit: (id: string) => {
              setViewId(id)
              setIsEditOpen(true)
            },
          },
          userId: viewer.id,
          baseLink: `/site/${site.id}/trend`,
        })}
        actions={{ selectRow: handleNavigateToCreatedTrendView }}
      />
      <EditViewModal
        isLoading={updateViewMutation.isLoading}
        viewName={selectedView?.name ?? ''}
        siteName={site.name}
        shared={selectedView?.shared ?? false}
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        onConfirm={handleEditView}
      />
      <DeleteViewModal
        isLoading={deleteViewMutation.isLoading}
        viewName={selectedView?.name ?? ''}
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onConfirm={handleDeleteView}
      />
    </div>
  )
}
