import { useTheme } from 'styled-components'
import { color, label as generateLabel } from 'models/model/model.utils'
import { Text } from 'src/components/ui'
import classNames from 'classnames'

type Props = {
  title?: string
  hideBackground?: boolean
  state: string
  children?: React.ReactNode
  className?: string
}

export function ModelState({
  title,
  hideBackground,
  state,
  children,
  className,
}: Props): JSX.Element {
  const theme = useTheme()

  const content = (
    <Text variant="content">{children || generateLabel(state ?? '')}</Text>
  )

  if (!hideBackground) {
    return (
      <div
        title={title}
        className={classNames(
          'inline-block text-center px-[6px] py-[2px] leading-[1] rounded-2xs',
          className,
        )}
        style={{ backgroundColor: color({ state, theme }) }}
      >
        {content}
      </div>
    )
  } else {
    return content
  }
}
