import { Field, Form, Formik } from 'formik'
import { Text, Modal } from 'src/components/ui'
import React from 'react'
import * as Yup from 'yup'
import { useUpdateTagMutation } from 'tags/api'
import { GqlUpdateTagInput } from 'src/services'

type TagData = {
  tagNodeId: string
  tagName: string
  displayName?: string
  description?: string
  unit?: string
}

type Props = {
  tagData?: TagData
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

type FormValues = {
  displayName: string
  description: string
  unit: string
}

const FormSchema = Yup.object().shape({
  displayName: Yup.string(),
  description: Yup.string(),
  unit: Yup.string(),
})

export default function EditTagForm({
  tagData,
  isOpen,
  setIsOpen,
}: Props): JSX.Element {
  const [tagEditError, setTagEditError] = React.useState<string>('')

  const updateTagMutation = useUpdateTagMutation()

  const handleEditTagFormSubmit = async (values: FormValues): Promise<void> => {
    if (!tagData?.tagNodeId) return
    const mutateData: GqlUpdateTagInput = {
      tagId: tagData.tagNodeId,
      displayName: values.displayName,
      description: values.description,
      engUnit: values.unit,
    }
    if (mutateData.displayName === tagData.tagName)
      mutateData.displayName = null

    await updateTagMutation.mutateAsync(mutateData, {
      onSuccess: () => {
        setIsOpen(false)
      },
      onError: (err: any) => {
        setTagEditError(err.message.split(':')[0])
      },
    })
  }

  if (!tagData?.displayName || !tagData?.tagNodeId) return <></>

  return (
    <Formik
      initialValues={{
        displayName: tagData.displayName,
        description: tagData.description ?? '',
        unit: tagData.unit ?? '',
      }}
      enableReinitialize
      validationSchema={FormSchema}
      onSubmit={handleEditTagFormSubmit}
    >
      {({ errors, touched, isValid, values }) => (
        <Modal
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          title={'Edit tag'}
          footer={
            <Modal.Footer
              onCancel={() => {
                setIsOpen(false)
              }}
              onConfirm={() => handleEditTagFormSubmit(values)}
              confirmLabel="Save"
              type="success"
              buttonDisabled={!isValid}
            />
          }
        >
          <>
            <Form>
              <div className="my-m flex flex-col gap-xs">
                <Text variant="small" bold>
                  Source name
                </Text>
                <Text variant="description" bold>
                  {tagData?.tagName}
                </Text>
              </div>
              <div className="relative my-m">
                <label className="mb-xs block" htmlFor="name">
                  <Text variant="small" bold className="my-xs">
                    Display name
                  </Text>
                </label>
                <Field
                  name="displayName"
                  placeholder="Display name"
                  className="mb-m flex w-full items-center rounded-2xs border-none pl-xs text-xs font-medium leading-[32px] outline-none ring-1 ring-border ring-offset-1 placeholder:font-thin"
                ></Field>
                <Text
                  variant="small"
                  className="absolute -bottom-s left-0 text-text-danger"
                >
                  {errors.displayName && touched.displayName
                    ? errors.displayName
                    : null}
                </Text>
              </div>
              <div className="relative my-m">
                <label className="mb-xs block" htmlFor="name">
                  <Text variant="small" bold className="my-xs">
                    Description
                  </Text>
                </label>
                <Field
                  name="description"
                  placeholder="Description"
                  className="mb-m flex w-full items-center rounded-2xs border-none pl-xs text-xs font-medium leading-[32px] outline-none ring-1 ring-border ring-offset-1 placeholder:font-thin"
                ></Field>
                <Text
                  variant="small"
                  className="absolute -bottom-s left-0 text-text-danger"
                >
                  {errors.description && touched.description
                    ? errors.description
                    : null}
                </Text>
              </div>
              <div className="relative my-m">
                <label className="mb-xs block" htmlFor="name">
                  <Text variant="small" bold className="my-xs">
                    Unit
                  </Text>
                </label>
                <Field
                  name="unit"
                  placeholder="Unit"
                  className="mb-m flex w-full items-center rounded-2xs border-none pl-xs text-xs font-medium leading-[32px] outline-none ring-1 ring-border ring-offset-1 placeholder:font-thin"
                ></Field>
                <Text
                  variant="small"
                  className="absolute -bottom-s left-0 text-text-danger"
                >
                  {errors.unit && touched.unit ? errors.unit : null}
                </Text>
              </div>
            </Form>
            {tagEditError && (
              <Text variant="small" className="capitalize text-text-danger">
                {tagEditError}
              </Text>
            )}
          </>
        </Modal>
      )}
    </Formik>
  )
}
