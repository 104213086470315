import React from 'react'
import { throttle } from 'lodash'
import { useCursor as useCursorContext } from 'src/contexts/cursor'
import useTimeRange from 'src/contexts/timeRange'
import HighchartsReact from 'highcharts-react-official'
import {
  isChartXMouseEvent,
  isDefined,
  isPlotPoint,
  mapColor,
  PlotPoint,
} from 'src/types'

interface UseCursorProps {
  ref: React.RefObject<HighchartsReact.RefObject>
  height?: number
  withTrackballs?: boolean
}

export function useCursor({
  ref,
  height,
  withTrackballs = false,
}: UseCursorProps): void {
  const [cursor, setCursor] = useCursorContext()
  const trackballs = React.useRef<Highcharts.SVGElement[][]>([])
  const crosshair = React.useRef<Highcharts.SVGElement>()
  const { timeRange } = useTimeRange()

  // See: https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
  const updateCursor = React.useMemo(
    () => throttle(setCursor, 100, { trailing: true }),
    [setCursor],
  )

  React.useEffect(() => {
    const { current } = ref
    if (current) {
      const { container } = current
      if (container.current) {
        container.current.addEventListener('mousemove', e => {
          if (isChartXMouseEvent(e)) {
            const { plotLeft, plotWidth } = current.chart
            const x = e.chartX - plotLeft
            const res = x / plotWidth
            if (res >= 0 && res <= 1) {
              updateCursor(res)
            }
          }
        })
      }
    }
  }, [ref, updateCursor])

  React.useEffect(() => {
    if (ref.current) {
      crosshair.current = ref.current.chart.renderer
        .path()
        .attr({
          'stroke-width': 1,
          stroke: 'black',
          zIndex: 3,
          display: 'none',
        })
        .add()
    }

    return () => {
      if (crosshair.current) {
        crosshair.current.destroy()
      }
    }
  }, [ref])

  React.useEffect(() => {
    if (ref.current && crosshair.current) {
      const { plotLeft, plotTop, plotHeight } = ref.current.chart
      crosshair.current.attr(
        'd',
        `M ${plotLeft} ${plotTop} L ${plotLeft} ${plotTop + plotHeight}`,
      )
    }
  }, [ref, height])

  const newTrackballs = ({
    chart: { plotLeft, plotTop, renderer },
    userOptions: { color },
  }: Highcharts.Series): Highcharts.SVGElement[] =>
    Array(2)
      .fill(undefined)
      .map(() =>
        renderer
          .circle(plotLeft, plotTop, 5)
          .attr({
            stroke: 'white',
            'stroke-width': 1,
            zIndex: 5,
          })
          .css({
            display: 'none',
            fill: mapColor(color),
          })
          .add(),
      )

  const updateTrackballs = (
    {
      plotX,
      plotY,
      plotHigh,
      plotLow,
      series: {
        type,
        userOptions: { color },
      },
    }: PlotPoint,
    trackballs: Highcharts.SVGElement[],
  ): void =>
    (type === 'arearange' ? [plotHigh, plotLow] : [plotY]).forEach((y, index) =>
      trackballs[index].css({
        transform: `translate3d(${plotX}px, ${y}px, 0)`,
        display: 'initial',
        fill: mapColor(color),
      }),
    )

  React.useEffect(() => {
    if (ref.current) {
      const { chart } = ref.current
      if (cursor) {
        const x = cursor * chart.plotWidth

        const points = chart.series
          .map(({ points }) =>
            points
              .filter(isPlotPoint)
              .find(
                ({ plotX }, i, a) =>
                  i < a.length - 1 && plotX <= x && a[i + 1].plotX > x,
              ),
          )
          .filter(isDefined)

        if (points.length > 0) {
          chart.tooltip.refresh(points)
        } else {
          chart.tooltip.destroy()
        }
      } else {
        chart.tooltip.destroy()
      }
    }
  }, [ref, cursor, height])

  React.useEffect(() => {
    if (trackballs.current) {
      trackballs.current.forEach(a =>
        a.forEach(t => t.css({ display: 'none' })),
      )
    }
  }, [timeRange])

  React.useEffect(() => {
    if (cursor && withTrackballs && ref.current) {
      const { chart } = ref.current
      const x = cursor * chart.plotWidth

      chart.series
        .map(({ points }) =>
          points
            .filter(isPlotPoint)
            .find(
              ({ plotX }, i, a) =>
                i < a.length - 1 && plotX <= x && a[i + 1].plotX > x,
            ),
        )
        .forEach((point, i) => {
          if (point) {
            if (!trackballs.current[i]) {
              trackballs.current[i] = newTrackballs(point.series)
            } else {
              updateTrackballs(point, trackballs.current[i])
            }
          }
        })
    }
  }, [ref, cursor, withTrackballs, height])

  React.useEffect(() => {
    if (cursor && crosshair.current && ref.current) {
      crosshair.current.css({
        transform: `translate3d(${
          cursor * ref.current.chart.plotWidth
        }px, 0, 0)`,
        display: 'initial',
      })
    }
  }, [ref, cursor, height])
}
