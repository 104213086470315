import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { DynamicTable } from 'src/components/ui'
import { useAssetHierarchy } from 'src/contexts/assetHierarchy/useAssetHierarchy'
import { SiteRole } from 'src/types'
import EditTagForm from './EditTagForm'
import { useSite } from 'src/contexts/site'
import { ChartOptions, ChartType } from 'src/types/chartTypes'
import queryString from 'query-string'
import { TagDto } from 'src/services'
// TODO: This will get moved
import { getAssetTagsTableConfig } from 'src/components/ui/tables/DynamicTable'

type Props = {
  assetTags: TagDto[]
  shouldDeselect?: boolean
}

export function AssetTagTable({
  assetTags,
  shouldDeselect,
}: Props): JSX.Element {
  const [isTagEditModalOpen, setIsTagEditModalOpen] = React.useState(false)
  const { selectedTags, setSelectedTags } = useAssetHierarchy()
  const { rootLink, viewerRole: role, id: factory } = useSite()
  const navigate = useNavigate()
  const selectedTagsRef = React.useRef(selectedTags)

  React.useEffect(() => {
    selectedTagsRef.current = selectedTags
  }, [selectedTags])

  const generateTrendLink = useCallback((): string => {
    const charts = selectedTagsRef.current
      .map(el => el.tagName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map<ChartOptions>((tagName, id) => ({
        type: ChartType.TimeSeries,
        id,
        data: [{ type: 'tag', id: tagName }],
      }))
    return `${rootLink}/trend?${queryString.stringify({
      charts: JSON.stringify(charts),
    })}`
  }, [rootLink])

  const handleViewInTrend = useCallback(() => {
    navigate(generateTrendLink())
  }, [generateTrendLink, navigate])

  const handleEditTag = (): void => {
    setIsTagEditModalOpen(true)
  }

  const handleTagDetails = useCallback(() => {
    if (selectedTagsRef.current[0]) {
      navigate(
        `${rootLink}/tags/${encodeURIComponent(
          selectedTagsRef.current[0]?.tagName,
        )}`,
      )
    }
  }, [navigate, rootLink])

  const contextItems = useMemo(
    () => [
      {
        id: '1',
        name: 'View in trend',
        action: handleViewInTrend,
      },
      {
        id: '2',
        name: 'Edit tag',
        action: handleEditTag,
      },
      {
        id: '3',
        name: 'Tag details',
        action: handleTagDetails,
      },
    ],
    [handleTagDetails, handleViewInTrend],
  )

  const filteredMenuItems = React.useMemo(() => {
    return contextItems.filter(i => {
      if (i.id === '2') {
        return role === SiteRole.ADMIN
      }
      return true
    })
  }, [contextItems, role])

  const getContextMenuItems = useCallback(
    (props: any) => {
      // if ser opens context menu outside of node row return empty array
      if (!props.node?.data?.id) return []
      // if user opens context menu on a not yet selected item include that item in the lenght check
      const isIncluded = selectedTagsRef.current
        .map(el => el.id)
        .includes(props.node?.data?.id)
      return (selectedTagsRef.current.length == 1 && isIncluded) ||
        (selectedTagsRef.current.length == 0 && !isIncluded)
        ? filteredMenuItems
        : filteredMenuItems.filter(i => i.id === '1')
    },
    [filteredMenuItems],
  )

  const AssetTagsTable = useMemo(() => {
    return (
      <DynamicTable
        id={`${factory}-assetTagsTable`}
        maxHeightFull
        config={getAssetTagsTableConfig({ canDrag: role !== SiteRole.READER })}
        data={assetTags.map(t => ({
          ...t,
          id: t.id.toString(),
          displayName: t.displayName || t.tagName,
        }))}
        selectMultipleRows
        actions={{
          setSelectedRows: setSelectedTags,
        }}
        shouldDeselect={shouldDeselect}
        getContextItems={getContextMenuItems}
        fuzzySearch
        fuzzySearchField={['tagName', 'description', 'displayName', 'engUnit']}
      />
    )
  }, [
    factory,
    role,
    assetTags,
    setSelectedTags,
    shouldDeselect,
    getContextMenuItems,
  ])

  return (
    <>
      {AssetTagsTable}
      <EditTagForm
        tagData={{
          ...selectedTags[0],
          displayName: selectedTags[0]?.displayName || selectedTags[0]?.tagName,
          unit: selectedTags[0]?.engUnit,
        }}
        isOpen={isTagEditModalOpen}
        setIsOpen={setIsTagEditModalOpen}
      />
    </>
  )
}
