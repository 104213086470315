import { RefObject } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { getGraphColor } from '../../../../../utility/graphUtils/graphColors'
import { baseChartConfig } from './baseChartConfig'
import { GqlErrorScoreKind, GqlThresholdKind } from 'src/services'

interface ChartProps {
  theRef: RefObject<HighchartsReact.RefObject>
  wrapperRef: RefObject<HTMLDivElement>
  data: any[]
  setTimeRange: any
  height?: number
  tooltip: any
  staticTimeRange: any
  threshold: any
  thresholdType?: GqlThresholdKind
  errorScoreKind?: GqlErrorScoreKind
  yAxis: any
  unit: any
  breaks: any
  setYAxisRange: any
  yAxisPerData?: boolean
}

export function Chart({
  theRef,
  wrapperRef,
  data = [],
  setTimeRange,
  height = 256,
  tooltip: tooltipEnabled,
  staticTimeRange,
  threshold,
  thresholdType,
  errorScoreKind,
  yAxis = {},
  breaks = [],
  setYAxisRange = () => {},
  yAxisPerData,
}: ChartProps): JSX.Element {
  return (
    <div
      className="h-full"
      style={{ transform: 'translateZ(0)' }}
      ref={wrapperRef}
    >
      <HighchartsReact
        ref={theRef}
        highcharts={Highcharts}
        options={baseChartConfig({
          data,
          setTimeRange,
          height,
          tooltipEnabled,
          staticTimeRange,
          threshold,
          thresholdType,
          errorScoreKind,
          yAxis,
          breaks,
          setYAxisRange,
          getGraphColor,
          yAxisPerData,
        })}
      />
    </div>
  )
}
