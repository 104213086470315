import { TimeSeriesData } from 'src/types'
import * as api from 'src/services'
import {
  useQueries,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import { processTimeSeries } from 'src/utility/timeSeries'
import { TimeRange } from 'src/types'
import useTimeRange from 'src/contexts/timeRange'
import { timeSeriesRefetch } from 'trend/api'
import { minutesToMilliseconds, sub } from 'date-fns'

type Request = {
  id: string
  timeRange: TimeRange
  factory: string
}

// wrap the api call to take an object
async function getProcessValues(
  req: Request,
): Promise<api.GqlTsDataFragment[]> {
  const { from, to: origTo } = req.timeRange
  const now = Date.now()

  // we don't have tag data in the future
  if (from > now) {
    return []
  }

  // if `to` is in the future, truncate it to now
  const to = origTo > now ? now : origTo

  return api.getProcessValues(req.id, from, to, req.factory)
}

export function tagDataQuery(req: Request): UseQueryOptions<TimeSeriesData[]> {
  return {
    queryKey: ['tagData', req],
    queryFn: async () => {
      const data = await getProcessValues(req)
      return processTimeSeries(data)
    },
    refetchInterval: () => timeSeriesRefetch(req.timeRange),
  }
}

function optionalTagDataQuery(
  req: Request | undefined,
): UseQueryOptions<TimeSeriesData[]> {
  if (req) {
    return tagDataQuery(req)
  }
  return { enabled: false }
}

interface UseTagData {
  id: string
  timeRange?: TimeRange
}

// an undefined request means we are not yet ready
export function useTagData(
  props: UseTagData | undefined,
): UseQueryResult<TimeSeriesData[]> {
  const { id: factory } = useSite()
  const { timeRange } = useTimeRange()
  // allow props.timeRange to override the above timeRange
  const req = props ? { factory, timeRange, ...props } : undefined
  const query = optionalTagDataQuery(req)
  return useQuery(query)
}

interface UseTagsData {
  ids: string[]
  timeRange: TimeRange
}

export function useTagsData({
  ids,
  timeRange,
}: UseTagsData): UseQueryResult<TimeSeriesData[], unknown>[] {
  const { id: factory } = useSite()
  const queries = ids.map(id => tagDataQuery({ id, factory, timeRange }))
  return useQueries({ queries })
}

type SparklineRequest = {
  tagname: string
  factory: string
}

async function getSparkLineData(
  req: SparklineRequest,
  signal?: AbortSignal,
): Promise<TimeSeriesData[]> {
  const { tagname, factory } = req
  const now = new Date()
  const result = await api.getProcessValues(
    tagname,
    sub(now, { minutes: 60 }),
    now,
    factory,
    60, // one point per minute
    signal,
  )
  return processTimeSeries(result)
}

export function useSparklineData(
  tagname: string,
): UseQueryResult<TimeSeriesData[]> {
  const { id: factory } = useSite()
  const req = { factory, tagname }
  return useQuery({
    queryKey: ['sparklineData', req],
    queryFn: ({ signal }) => getSparkLineData(req, signal),
    // the data is stale after 5 min
    staleTime: minutesToMilliseconds(5),
  })
}
