import { useEffect, ReactNode, useReducer } from 'react'
import { useSite } from 'src/contexts/site'
import { TimeRange } from 'src/types'
import { timeRangeUrlProps } from 'src/utility/time'
import { useUrlQuery } from 'src/utility'
import { metropolisDataRange } from 'src/utility/metropolis'
import { ParsedQuery } from 'query-string'
import { produce } from 'immer'
import { isValid, milliseconds, parseISO } from 'date-fns'

import { last24Hours, LiveInput, TimeRangeContext } from './TimeRangeContext'

function parseTimestamp(ts: string | string[] | null): number | undefined {
  if (typeof ts === 'string') {
    // 20220602T095900+0200
    const d = parseISO(ts)
    return isValid(d) ? d.valueOf() : undefined
  }
  return undefined
}

function now(): number {
  const d = new Date()
  return d.valueOf()
}

// calculate the update period in milliseconds from
// the time range
function updatePeriod({ from, to }: TimeRange): number {
  const range = to - from

  if (range < milliseconds({ minutes: 30 })) {
    return milliseconds({ seconds: 10 })
  }
  if (range < milliseconds({ hours: 3 })) {
    return milliseconds({ seconds: 20 })
  }
  if (range < milliseconds({ hours: 24 })) {
    return milliseconds({ seconds: 60 })
  }
  return milliseconds({ minutes: 3 })
}

// takes a timeRange and keeping the same period it moves the
// `to` time to now
function moveTimeRangeToLive(timeRange: TimeRange): TimeRange {
  const period = timeRange.to - timeRange.from
  const to = now()
  const from = to - period
  return { from, to }
}

type State = {
  timeRange: TimeRange
  live: boolean
  isLiveUpdate: boolean
}

type InitializeValues = {
  isWorkshop: boolean
  defaultTimeRange?: TimeRange
  urlQuery?: boolean
  query: ParsedQuery<string>
}

function createInitialState({
  isWorkshop,
  defaultTimeRange,
  urlQuery,
  query,
}: InitializeValues): State {
  const defaultRange =
    defaultTimeRange ?? (isWorkshop ? metropolisDataRange : last24Hours())

  if (!urlQuery) {
    // if we're not interacting with the query string, then our work here is done
    return { timeRange: defaultRange, live: false, isLiveUpdate: false }
  }

  // make the time range from the query string if both `from` and `to` are valid
  // otherwise use the default range
  const from = parseTimestamp(query.from)
  const to = parseTimestamp(query.to)
  const timeRange = from && to ? { from, to } : defaultRange

  if (query.live === 'true') {
    return {
      timeRange: moveTimeRangeToLive(timeRange),
      live: true,
      isLiveUpdate: false,
    }
  }

  return { timeRange, live: false, isLiveUpdate: false }
}

type Action =
  | { type: 'setRange'; timeRange: TimeRange }
  | ({ type: 'setLive' } & LiveInput)
  | { type: 'liveUpdate' }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setRange':
      return produce(state, draft => {
        draft.timeRange = action.timeRange
        draft.live = false
        draft.isLiveUpdate = false
      })
    case 'setLive':
      return produce(state, draft => {
        if (action.live) {
          draft.live = true
          if (action.period) {
            const to = now()
            const from = to - action.period
            draft.timeRange = { from, to }
          } else {
            draft.timeRange = moveTimeRangeToLive(draft.timeRange)
          }
        } else {
          draft.live = false
        }
        draft.isLiveUpdate = false
      })
    case 'liveUpdate':
      return produce(state, draft => {
        // sanity check
        if (draft.live) {
          draft.timeRange = moveTimeRangeToLive(state.timeRange)
          draft.isLiveUpdate = true
        }
      })
    default: {
      const x: never = action
      return x
    }
  }
}

type HistoryState = {
  current: State
  history: State[]
}

function createInitialHistoryState(init: InitializeValues): HistoryState {
  const current = createInitialState(init)
  return { current, history: [] }
}

type HistoryAction = Action | { type: 'undo' }

function historyReducer(
  state: HistoryState,
  action: HistoryAction,
): HistoryState {
  switch (action.type) {
    case 'setRange':
    case 'setLive':
      // update the history
      return produce(state, draft => {
        draft.history.push(state.current)
        draft.current = reducer(draft.current, action)
      })
    case 'liveUpdate':
      // don't update history
      return produce(state, draft => {
        draft.current = reducer(draft.current, action)
      })
    case 'undo':
      return produce(state, draft => {
        const current = draft.history.pop()
        if (current) {
          if (current.live) {
            current.timeRange = moveTimeRangeToLive(current.timeRange)
          }
          draft.current = current
        }
      })
    default: {
      const x: never = action
      return x
    }
  }
}

interface TimeRangeProps {
  urlQuery?: boolean
  defaultTimeRange?: TimeRange
  children: ReactNode
}

export const TimeRangeProvider = ({
  urlQuery = false,
  defaultTimeRange,
  children,
}: TimeRangeProps): JSX.Element => {
  const { isWorkshop } = useSite()
  const { query, update } = useUrlQuery()

  const [{ current, history }, dispatch] = useReducer(
    historyReducer,
    { defaultTimeRange, isWorkshop, urlQuery, query },
    createInitialHistoryState,
  )

  // update the url when timeRange or live changes
  useEffect(() => {
    if (urlQuery) {
      const { from, to } = timeRangeUrlProps(current.timeRange)
      const live = current.live ? 'true' : 'false'
      if (from !== query.from || to !== query.to || live !== query.live) {
        update({ from, to, live })
      }
    }
  }, [query.from, query.live, query.to, current, update, urlQuery])

  // update periodically in live mode
  // the ms value is outside of the effect so that
  // changes to the range to & from do not clear the interval, only
  // changes to the length of the range
  const ms = updatePeriod(current.timeRange)
  useEffect(() => {
    if (current.live) {
      const interval = setInterval(() => dispatch({ type: 'liveUpdate' }), ms)
      return () => clearInterval(interval)
    }
    return undefined
  }, [ms, current.live])

  return (
    <TimeRangeContext.Provider
      value={{
        timeRange: current.timeRange,
        live: current.live,
        isLiveUpdate: current.isLiveUpdate,
        setTimeRange: (timeRange: TimeRange) =>
          dispatch({ type: 'setRange', timeRange }),
        setLive: (input: LiveInput) => dispatch({ type: 'setLive', ...input }),
        undo: () => dispatch({ type: 'undo' }),
        hasHistory: history.length > 0,
      }}
    >
      {children}
    </TimeRangeContext.Provider>
  )
}
