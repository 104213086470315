import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@components/breadcrumb'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Icon, Text } from 'src/components/ui'
import { Dropdown } from '../DropdownMenu/Dropdown'

type NavigationItem = {
  label: string
  url: string
  child?: NavigationItem
}
type BreadcrumbNavigationProps = {
  navigationPath: NavigationItem
}

function flattenNavigationPath(
  navigationItem: NavigationItem,
): Array<Omit<NavigationItem, 'child'>> {
  const result = []

  let currentItem = navigationItem

  while (currentItem) {
    result.push({
      label: currentItem.label,
      url: currentItem.url,
    })
    if (!currentItem.child) break
    currentItem = currentItem.child
  }

  return result
}

export function BreadcrumbNavigation({
  navigationPath,
}: BreadcrumbNavigationProps): JSX.Element {
  const navigationArray = flattenNavigationPath(navigationPath)
  const dropdownItems =
    navigationArray.length > 3 ? navigationArray.slice(1, -2) : []

  const renderBreadcrumbItem = (item: NavigationItem): JSX.Element => (
    <BreadcrumbItem key={item.url}>
      <Link className="text-inherit no-underline" to={item.url}>
        <Text bold>{item.label}</Text>
      </Link>
    </BreadcrumbItem>
  )

  const renderBreadcrumbSeparator = (): JSX.Element => (
    <BreadcrumbSeparator>
      <Icon icon={light('chevron-right')} />
    </BreadcrumbSeparator>
  )

  return (
    <Breadcrumb className="breadcrumbs">
      <BreadcrumbList>
        {dropdownItems.length > 0 ? (
          <>
            {renderBreadcrumbItem(navigationArray[0])}
            {renderBreadcrumbSeparator()}
            <Dropdown
              menuContent={{
                items: dropdownItems.map(item => ({
                  label: item.label,
                  url: item.url,
                })),
              }}
            >
              <BreadcrumbEllipsis data-testid="ellipsis" className="h-s" />
            </Dropdown>
            {renderBreadcrumbSeparator()}
            {navigationArray.slice(-2).map((item, index) => (
              <Fragment key={item.url}>
                {renderBreadcrumbItem(item)}
                {index < 1 && renderBreadcrumbSeparator()}
              </Fragment>
            ))}
          </>
        ) : (
          navigationArray.map((item, index) => (
            <Fragment key={item.url}>
              {renderBreadcrumbItem(item)}
              {index < navigationArray.length - 1 &&
                renderBreadcrumbSeparator()}
            </Fragment>
          ))
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
