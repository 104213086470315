import { useTags } from 'tags/api'
import { ChartAxis, ChartTableProps } from 'src/types/chartTypes'
import {
  DynamicTable,
  Text,
  Spinner,
  TableTag,
  TableConfig,
} from 'src/components/ui'
import { getTagsConfig } from './table-configs'

export function TagsTable(props: ChartTableProps): JSX.Element {
  const { data: tags = [], isLoading } = useTags()

  // map to the data required by the tag table
  const data: TableTag[] =
    tags.map(tag => ({
      id: tag.tagName,
      description: tag.description ?? '',
      engUnit:
        tag.engUnit !== null && tag.engUnit !== 'None' ? tag.engUnit! : '',
      displayTagName: tag.displayName ? tag.displayName : tag.tagName,
    })) ?? []

  const getConfig = (): [
    TableConfig<TableTag>,
    string[],
    { id: string; axis: ChartAxis }[],
  ] => {
    const { chart, addSeries, removeSeries } = props

    const selected = chart.data.flatMap(({ id, type }) =>
      type === 'tag' ? [id] : [],
    )

    const selectedScatter = chart.data.flatMap(({ id, axis }) =>
      axis === ChartAxis.X || axis === ChartAxis.Y ? [{ id, axis }] : [],
    )

    return [
      getTagsConfig({
        chartType: chart.type,
        addSeries,
        removeSeries,
      }),
      selected,
      selectedScatter,
    ]
  }

  const [config, selected, selectedScatter] = getConfig()

  return (
    <div className="h-full">
      <Text variant="title" bold>
        Select tags to view
      </Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="h-[calc(100%-24px)]">
          <DynamicTable
            id="TrendTags"
            data={data}
            config={config}
            selectedRows={selectedScatter.length ? undefined : selected}
            selectedScatter={selectedScatter}
            maxHeightFull
            fuzzySearch
            fuzzySearchField={['displayTagName', 'description', 'engUnit']}
            shouldAutofocusSearch
          />
        </div>
      )}
    </div>
  )
}
