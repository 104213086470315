import { useLocation } from 'react-router-dom'
import { Text } from 'src/components/ui'
import { UserMenu } from '../UserMenu'
import { OrganizationDropdown } from './OrganizationDropdown'
import { useNavigationContext } from 'src/contexts/navigation'

const exclude = ['sites', 'invitations']

export function SettingsTopNavigation(): JSX.Element {
  const { pathname } = useLocation()
  const pathSplit = pathname.split('/')
  const { titleComponent } = useNavigationContext()
  // explicitly make the empty string undefined
  const orgId = pathSplit[pathSplit.indexOf('orgs') + 1] || undefined
  const siteId = pathSplit[pathSplit.indexOf('sites') + 1] || undefined

  return (
    <div className="relative flex h-[50px] items-center justify-between p-s font-[Rubik]">
      <div className="flex items-center">
        {titleComponent ? (
          titleComponent
        ) : orgId && !exclude.includes(orgId) ? (
          <OrganizationDropdown orgId={orgId} siteId={siteId} />
        ) : (
          <Text variant="title" bold className="my-xs">
            {(window.location.pathname.startsWith('/settings/orgs') &&
              'Organizations & Sites') ||
              (window.location.pathname === '/settings' && 'Account Settings')}
          </Text>
        )}
      </div>
      <UserMenu />
    </div>
  )
}
