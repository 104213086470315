import React from 'react'
import { Button, Stepper } from 'src/components/ui'
import { useTitle } from 'src/utility'
import { steps, Record } from './steps'
import { CreateModelButton } from './components'

const initModel: Record = {
  modelType: null,
  outputTag: null,
  inputTags: [],
  name: '',
}

export function CreateModelPage(): JSX.Element {
  useTitle('Create model')
  const [step, setStep] = React.useState(0)
  const [model, setModel] = React.useState(initModel)
  const Step = steps[step].render

  const handleNextStep = (nextStep: number): void => {
    if (nextStep > step) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      steps[nextStep - 1].completed(model) && setStep(nextStep)
    } else {
      setStep(nextStep)
    }
  }

  const handleUpdateModel = (updates: Record): void => {
    if (updates && updates.modelType) {
      setStep(1)
    }
    setModel(model => ({ ...model, ...updates }))
  }

  const showNext = step > 0 && step < steps.length - 1
  const showCreate = step === steps.length - 1

  return (
    <div
      className="mx-auto my-0 p-[1em]"
      style={{ maxWidth: steps[step].maxWidth ?? 1200 }}
    >
      <div className="flex w-full justify-center">
        <Stepper
          steps={steps.map((s, i) => ({
            label: s.label,
            state:
              step === i
                ? 'active'
                : s.completed(model)
                ? 'completed'
                : 'default',
          }))}
          onClick={handleNextStep}
        />
      </div>
      <Step model={model} updateModel={handleUpdateModel} />
      <div className="flex justify-between">
        {step === 0 ? (
          <div />
        ) : (
          <Button
            variant="secondary"
            title="Back"
            onClick={() => setStep(step => step - 1)}
          />
        )}
        {showNext && (
          <Button
            title="Next"
            variant={steps[step].completed(model) ? 'primary' : 'secondary'}
            disabled={!steps[step].completed(model)}
            onClick={() => handleNextStep(step + 1)}
          />
        )}
        {showCreate && (
          <CreateModelButton
            disabled={steps.some(step => !step.completed(model))}
            state={model}
          />
        )}
      </div>
    </div>
  )
}
