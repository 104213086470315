import { GraphQLClient, ClientError } from 'graphql-request'
import { API_ROOT } from '../config'
import { getToken } from './auth'
import { logError } from '../utility/logging'
import { Sdk, getSdk, SdkFunctionWrapper } from './graphqlTypes'
import traceparent from './traceparent'

async function graphQLClient(signal?: AbortSignal): Promise<GraphQLClient> {
  const token = await getToken()
  return new GraphQLClient(`${API_ROOT}/graphql`, {
    headers: {
      Authorization: `Bearer ${token}`,
      traceparent: traceparent(),
    },
    signal,
  })
}

const errorHandlingWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>,
  operationName: string,
): Promise<T> => {
  try {
    return await action()
  } catch (error) {
    const ctx: Record<string, any> = {
      source: 'graphql',
      api_root: API_ROOT,
      operationName,
    }

    if (error instanceof ClientError) {
      ctx.errors = error.response.errors

      // FIXME work out a way to log the variables
      // if (variables) {
      //   ctx.variables = error.request.variables
      // }

      logError(new Error(error.message), ctx)
    } else if (error instanceof Error) {
      logError(error, ctx)
    }

    throw error
  }
}

export async function graphqlApi(signal?: AbortSignal): Promise<Sdk> {
  const client = await graphQLClient(signal)
  return getSdk(client, errorHandlingWrapper)
}
