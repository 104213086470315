import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useOrganizationSites } from 'orgs-sites/org/api'
import { TextInput } from 'src/components/ui'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SiteCard } from '../../main'
import { siteCompare } from '../../orgsSites.utils'

export function OrgSites(): JSX.Element {
  const [filter, setFilter] = useState('')
  const { orgId } = useParams()
  if (!orgId) throw new Error('`orgId` route param missing')
  const { data: orgSites } = useOrganizationSites({ orgId })

  return (
    <>
      <div className="max-w-[200px] pt-s">
        {(orgSites?.length ?? 0) > 3 && (
          <TextInput
            iconLeft={light('magnifying-glass')}
            variant="underlined"
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />
        )}
      </div>

      <div className="flex w-full flex-wrap content-start items-center justify-start gap-s pt-s">
        {orgSites
          ?.filter(s => {
            if (!filter) return true
            return s.name.toLowerCase().includes(filter.toLowerCase())
          })
          .sort(siteCompare)
          .map(site => (
            <SiteCard site={site} key={site.id} />
          ))}
      </div>
    </>
  )
}
