import { prettyDate, prettyTime } from 'src/utility/time'
import { DefaultTheme } from 'styled-components'

const subtle = (value: string): string =>
  `<div style="color: gray; font-size: 0.75em">${value}</div>`
const emphasized = (value: string): string =>
  `<div style="font-weight: bold; display: flex; align-items: center;">${value}</div>`

export type CustomPoint = Highcharts.Point & { time: number }
type Props = {
  colorIndex?: number
  theme: DefaultTheme
}
export const baseScatterChartConfig = ({
  theme,
  colorIndex,
}: Props): Highcharts.Options => {
  const defineColor = (): string => {
    if (colorIndex) {
      return `${theme.colors.chart[colorIndex % theme.colors.chart.length]}4C`
    }
    return `${theme.colors.chart[0]}4C`
  }

  return {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      marginLeft: 40,
      marginBottom: 20,
      spacingTop: 5,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: 'transparent',
      animation: false,
      resetZoomButton: {
        theme: {
          fill: 'white',
          stroke: '#00DC00',
          r: 4,
          states: {
            hover: {
              fill: '#CCF8CC',
            },
          },
        },
      },
      style: {
        fontFamily: 'Roboto',
      },
      events: {
        render() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const chart = this,
            // @ts-ignore
            { plotArea, plotWidth, plotHeight } = chart

          if (plotArea) {
            plotArea.attr({
              width: plotWidth,
              height: plotHeight,
            })
          } else {
            // @ts-ignore
            chart.plotArea = chart.renderer
              .rect(chart.plotLeft, chart.plotTop, plotWidth, plotHeight)
              .attr({
                fill: 'transparent',
                zIndex: 1,
                cursor: 'crosshair',
              })
              .add()
          }
        },
      },
    },
    title: {
      text: undefined,
    },
    xAxis: {
      title: {
        text: undefined,
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      left: 20,
      labels: {
        align: 'left',
      },
    },
    tooltip: {
      animation: false,
      borderRadius: 8,
      borderWidth: 0,
      followPointer: false,
      formatter: function () {
        const point = this.point as CustomPoint
        if (!point) return ''
        return [
          subtle(prettyDate(point.time)),
          subtle(prettyTime(point.time)),
          emphasized('x: ' + point.x.toFixed(2).toString()),
          emphasized('y: ' + point.y?.toFixed(2).toString()),
        ].join('')
      },
      padding: 6,
      shadow: false,
      shared: true,
      style: {
        color: 'black',
        fontSize: '1em',
      },
      useHTML: true,
    },
    plotOptions: {
      scatter: {
        animation: false,
        marker: {
          radius: 5,
          fillColor: defineColor(),
        },
      },
      series: {
        animation: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  }
}
