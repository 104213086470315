import { TimeRangeContext } from './TimeRangeContext'
import { Forecast, useForecasts } from 'models/api'
import { isDefined, TimeRange } from 'src/types'
import { add, sub } from 'date-fns'

// hopefully this never gets used
// assume the the forcasts were made 30min ago
// add 3 hours history -> from = now - 3.5 hours.
// max forecast is 4hours ->  to = now + 3.5 hours.
// 3.5 hours = 210 minutes
const defaultForecastRange = (): TimeRange => {
  const now = new Date()
  return {
    from: sub(now, { minutes: 210 }).valueOf(),
    to: add(now, { minutes: 210 }).valueOf(),
  }
}

function forecastMinMax(f: Forecast): TimeRange {
  return {
    from: f.madeAt,
    // get the time of the last prediction
    to: f.prediction[f.prediction.length - 1][0],
  }
}

function forecastsTimeRange(fs: Forecast[]): TimeRange | undefined {
  if (fs.length === 0) {
    return undefined
  }
  // get the time range for every forecast
  const trs = fs.map(f => forecastMinMax(f))
  // get the min from time
  const from = Math.min(...trs.map(tr => tr.from))
  // get the max to time
  const to = Math.max(...trs.map(tr => tr.to))
  return {
    from: sub(from, { hours: 3 }).valueOf(),
    to,
  }
}

interface ForecastProps {
  activeModels: string[]
  children: React.ReactNode
}

export const ForecastTimeRangeProvider = ({
  children,
  activeModels,
}: ForecastProps): JSX.Element => {
  const forecasts = useForecasts(activeModels)
    .map(q => q.data)
    .filter(isDefined)
  const timeRange = forecastsTimeRange(forecasts) ?? defaultForecastRange()

  return (
    <TimeRangeContext.Provider
      value={{
        timeRange,
        setTimeRange: () => {},
        live: false,
        setLive: () => {},
        undo: () => {},
        hasHistory: false,
        isLiveUpdate: false,
      }}
    >
      {children}
    </TimeRangeContext.Provider>
  )
}
