import React from 'react'
import { DisplayInputTags, AddInputTagsModal } from './components'
import { Record } from '../steps'
import { Text, Button } from 'src/components/ui'
import { useTag } from 'tags/api'

interface InputStepProps {
  outputTag?: string | null
  inputTags?: string[]
  model: Record
  addInputTag: (tag: string) => void
  removeInputTag: (tag: string) => void
}

export function InputStep({
  outputTag,
  inputTags,
  addInputTag,
  removeInputTag,
  model,
}: InputStepProps): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false)
  const { data: tag } = useTag(outputTag ?? '')

  return (
    <>
      <Text variant="title" bold className="my-m text-center !text-3xl">
        Which values are good predictors of {tag?.displayName || 'the output'}?
      </Text>
      {inputTags && inputTags.length === 0 ? (
        <Text className="my-s">
          A good model requires a set of input tags that can explain how the
          output tag changes over time. Select the input tags that help explain
          how the output tag changes over time.
        </Text>
      ) : (
        <DisplayInputTags
          inputTags={inputTags}
          removeInputTag={removeInputTag}
          model={model}
        />
      )}
      <Button
        title="+ Add input tag"
        variant="primary"
        className="mb-[0.5em]"
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <AddInputTagsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          inputTags={inputTags}
          addInputTag={addInputTag}
          model={model}
        />
      )}
    </>
  )
}
