import { TagAction } from 'pages/site/models'
import { DynamicTable, Banner, Spinner } from 'src/components/ui'
import { useTags } from 'tags/api'
import { TagDto } from 'src/services'
import { getTagInputListConfig } from 'models/model/table-configs'
import { getInputTagWarnings } from '../../steps.utils'
import { Record } from '../../steps'
import { useSite } from 'src/contexts/site'

interface ModelInputTagsProps {
  model: Record
  inputTags?: string[]
}

export interface WithTagProps extends ModelInputTagsProps {
  tag: TagDto
}

interface DisplayInputTagsProps extends ModelInputTagsProps {
  removeInputTag: (tag: string) => void
}

export function DisplayInputTags({
  inputTags,
  removeInputTag,
  model,
}: DisplayInputTagsProps): JSX.Element {
  const { id: factory } = useSite()
  const { data: tags = [], isLoading: isPending } = useTags()

  const hasWarning =
    inputTags &&
    inputTags
      .map(tagName => tags.find(tag => tag.tagName === tagName))
      .map(
        tag =>
          tag && getInputTagWarnings(tag, model.modelType, model.outputTag),
      )
      .some(warnings => warnings && warnings.length > 0)

  const actions = {
    renderAction: ({ data: tag }: any) => (
      <TagAction
        type="Display"
        tag={tag}
        tagAction={removeInputTag}
        modelType={model.modelType}
        modelOutputTagName={model.outputTag}
      />
    ),
  }

  // use flatMap to remove tags not found
  // FIXME maybe this should be an error?
  const displayTags = inputTags
    ? inputTags.flatMap(tagName => {
        const tag = tags.find(tag => tag.tagName === tagName)
        return tag ? [tag] : []
      })
    : []

  return (
    <>
      {hasWarning && (
        <Banner className="mb-xs" variant="warning">
          One or more of the selected tags have warnings.
        </Banner>
      )}

      <div style={{ marginBottom: '0.5em' }}>
        {isPending ? (
          <Spinner />
        ) : (
          <DynamicTable
            id={`${factory}-DisplayInputTags`}
            data={displayTags.map(t => ({
              ...t,
              displayName: t.displayName || t.tagName,
            }))}
            config={getTagInputListConfig(actions)}
            className="!h-[400px]"
            rowHeight={60}
          />
        )}
      </div>
    </>
  )
}
