import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { datadogRum, RumResourceEvent } from '@datadog/browser-rum'

import { theme } from 'src/style'
import { AppRouter, Toaster } from 'pages/app'

import App from './App'
import { STACK, API_ROOT } from './config'
import GlobalStyle from './GlobalStyle'

// CSS imports
import './style/highchartsOverride.css'
import './style/style.css'

let version = STACK

if (STACK !== 'unknown') {
  version = import.meta.env.VITE_VERSION ?? ''

  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APP_ID ?? '',
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN ?? '',
    site: 'datadoghq.com',
    service: import.meta.env.VITE_DD_SERVICE ?? '',
    env: STACK,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    enableExperimentalFeatures: ['clickmap'],
    startSessionReplayRecordingManually: true,
    proxy: API_ROOT + '/dd-telemetry',
    version,
    beforeSend: event => {
      if (event.type === 'resource') {
        const rsrcEvent = event as RumResourceEvent
        if (rsrcEvent.resource.status_code! >= 400) {
          datadogRum.addError(
            `${rsrcEvent.resource.method} ${rsrcEvent.resource.url} ${rsrcEvent.resource.status_code}`,
          )
        }
      }
      // return true to keep the event.
      return true
    },
  })
}

console.log(
  `Starting Intelecy Application version=${version} build_number=${
    import.meta.env.VITE_BUILD_NUMBER
  }`,
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="*" element={<AppRouter />} />
    </Route>,
  ),
)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Toaster />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
