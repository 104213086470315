import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

export function getAnalysisIcon(analysisType: string): IconProp {
  switch (analysisType) {
    case 'trend':
      return light('chart-line')
    case 'scatter':
      return light('chart-scatter')
    case 'parallelCoordinates':
      return light('columns-3')
    case 'overlaidSegments':
      return light('chart-mixed')
    // TODO: Add correct icon for quality analysis
    case 'qualityAnalysis':
      return light('chart-line')
    default:
      return light('chart-line')
  }
}

export function getSegmentationIcon(segmentationType: string): IconProp {
  switch (segmentationType) {
    case 'condition':
      return light('function')
    case 'valueChange':
      return light('arrow-trend-up')
    case 'slidingWindow':
      return light('calendar-clock')
    default:
      return light('function')
  }
}

export function getSeriesIcon(seriesType: string): IconProp {
  switch (seriesType) {
    case 'tagId':
      return light('sensor')
    case 'expression':
      return light('calculator-simple')
    case 'modelOutputId':
      return light('brain')
    case 'simulation':
      return light('wave-sine')
    default:
      return light('chart-mixed')
  }
}

export function getRangeIcon(rangeType: string): IconProp {
  switch (rangeType) {
    case 'static':
      return light('calendar')
    case 'relative':
      return light('reply-clock')
    default:
      return light('calendar')
  }
}
